import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  IconButton,
  Stack,
  Skeleton,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useSelector} from 'react-redux';
import {ISponsor, ISponsorshipRequestProp} from '../../dto/Sponsor.dto';
import {IProduct} from '../../dto/Company.dto';
import UploadButton from '../../components/styles/UploadButton';
import ExistingCampaignChooserBtn from './ExistingCampaignChooserBtn';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';
import {isInternalSelector} from '../../store/Slices/userSlice';
import {useStoreSelector} from '../../store/configstore';
import {companyIdSelector} from '../../store/Slices/companySlice';
import {
  useRqSponsorRequestDetails,
  useRqSponsorRequestDetailsByToken,
} from '../../react_query/sponsor_commercial/SponsorRequestDetails';
import dayjs from 'dayjs';
type Props = {
  sponsorshipRequest: ISponsorshipRequestProp;
  setSponsorshipRequest: Dispatch<SetStateAction<ISponsorshipRequestProp>>;
  type: number;
  product?: IProduct;
  companyName?: string;
  requestType?: string;
  token?: string;
};

const SponsorshipRequest = ({
  sponsorshipRequest,
  setSponsorshipRequest,
  type,
  product,
  companyName: companyname,
  requestType,
  token = '',
}: Props) => {
  const isStaff = useSelector(isInternalSelector);
  const companyId = useStoreSelector(companyIdSelector);
  const handleChange = useCallback(
    (
      value: string | File | undefined,
      field: 'request_format' | 'files' | 'message',
    ) => {
      let fieldKey = 'request_format';
      let fieldValue = field === 'message' ? 1 : 0;

      setSponsorshipRequest(prevInfo => {
        return {
          ...prevInfo,
          [field]: value,
          [fieldKey]: fieldValue,
          track: field === 'message' ? prevInfo.track : undefined,
        };
      });
    },
    [setSponsorshipRequest],
  );
  return (
    <>
      {token ? <SponsorRequestDetailsCard token={token} /> : null}
      <Typography variant='h5' className='text-white font-bold mb-8'>
        {requestType === 'campaign-created' ? 'Campaign Info' : 'Sponsor Info'}
      </Typography>

      {type === 1 && requestType !== 'campaign-created' && (
        <Typography
          variant='h6'
          className='mb-5'
          sx={{color: 'var(--green-variant16)'}}>
          Congratulations on becoming a {product?.primary} sponsor at{' '}
          {companyname}
        </Typography>
      )}

      <Typography variant='h6' className='mb-7 text-white-50'>
        {requestType === 'campaign-created'
          ? 'I am providing a pre-recorded campaign (MP3 file only)'
          : 'I am providing a pre-recorded commercial (MP3 file only)'}
      </Typography>
      <Stack direction='row' spacing={2}>
        <UploadButton
          handleChange={handleChange}
          disabled={
            Number(sponsorshipRequest.request_format) === 1 &&
            (sponsorshipRequest?.message?.length ?? 0) > 0
          }
        />
        {isStaff && (!token || requestType !== 'campaign-created') && (
          <ExistingCampaignChooserBtn
            disabled={
              Number(sponsorshipRequest.request_format) === 1 &&
              (sponsorshipRequest?.message?.length ?? 0) > 0
            }
            onChange={(tracks: IMediaTrack[]) => {
              setSponsorshipRequest(
                tracks.length
                  ? {
                      files: undefined,
                      track: tracks[0],
                      request_format: '0',
                    }
                  : prev => ({...prev, track: undefined}),
              );
            }}
            tracks={sponsorshipRequest.track ? [sponsorshipRequest.track] : []}
            label='Choose Sponsor Commercial'
            type='commercial'
            max={1}
            companyId={companyId}
          />
        )}
      </Stack>

      {(sponsorshipRequest.files || sponsorshipRequest.track) && (
        <FilePreview
          file={sponsorshipRequest.files}
          track={sponsorshipRequest.track}
          setSponsorshipRequest={setSponsorshipRequest}
        />
      )}

      <Divider>Or</Divider>
      <Typography className='my-5 text-white-50'>
        {type === 1
          ? requestType === 'campaign-created'
            ? 'I am providing all of the information needed to produce my campaign'
            : 'I am providing all of the information needed to produce my commercial'
          : 'I am providing all of the information needed to produce the commercial for my sponsor.'}
      </Typography>
      <TextField
        fullWidth
        className='radius-10'
        id='outlined-multiline-static'
        placeholder='Message'
        value={sponsorshipRequest.message}
        multiline
        onChange={event => handleChange(event.target.value, 'message')}
        disabled={
          Number(sponsorshipRequest.request_format) === 0 &&
          (sponsorshipRequest?.files !== undefined ||
            sponsorshipRequest?.track !== undefined)
        }
        rows={4}
      />
      {requestType !== 'campaign-created' && (
        <Box>
          <Typography variant='subtitle1' className='text-white-50 italic mt-3'>
            Please provide all of the information needed to produce the
            commercial for your sponsor.This information might include such
            details as commercial script,company info(including address,phone
            number or web address),company slogan or any other information you
            would like to be included in the commercial.
          </Typography>

          {sponsorshipRequest.request_format === '1' && (
            <Box>
              {type === 1 && (
                <List>
                  <ListItem>
                    <Typography>
                      Commercial will take 5-14 business days to complete.
                      Please ensure you are checking your primary inbox and spam
                      folders for information from production@neptunenow.com.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      We look forward to working with you to create a
                      professional radio commercial!
                    </Typography>
                  </ListItem>
                </List>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

type FilePreviewProps = {
  file: ISponsorshipRequestProp['files'];
  track: ISponsorshipRequestProp['track'];
  setSponsorshipRequest: Dispatch<SetStateAction<ISponsorshipRequestProp>>;
};

const FilePreview = ({
  file,
  track,
  setSponsorshipRequest,
}: FilePreviewProps) => {
  const handleDelete = () => {
    setSponsorshipRequest(prevInfo => ({
      ...prevInfo,
      files: undefined,
      track: undefined,
    }));
  };
  let url = '',
    name = '';
  if (file) {
    url = URL.createObjectURL(file);
    name = file.name;
  }
  if (track) {
    url = track.url;
    name = track.track_name;
  }
  return (
    <List sx={{mb: 2}}>
      <ListItem
        sx={{p: 2, background: '#0d1525'}}
        className='cursor-pointer'
        secondaryAction={
          <IconButton onClick={handleDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        }>
        <ListItemIcon>
          <MusicPreviewBtn id={url} url={url} />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </List>
  );
};

export default SponsorshipRequest;

export const SponsorRequestDetailsCard = ({
  sponsorRequestId,
  token,
  type,
}: {
  sponsorRequestId?: number;
  token?: string;
  type?: string;
}) => {
  const [sponsorDetails, setsponsorDetails] = useState<ISponsor | undefined>();
  const {data, isFetching} = useRqSponsorRequestDetails(
    sponsorRequestId ?? 0,
    Boolean(sponsorRequestId ?? 0),
  );
  const {data: tokenData, isFetching: tokenDataIsFetching} =
    useRqSponsorRequestDetailsByToken(token ?? '', Boolean(token ?? 0));
  const className =
    type && type === 'recording' ? 'w-130 text-bold' : 'w-248 text-bold';

  useEffect(() => {
    if (data && !isFetching) {
      setsponsorDetails(data);
    }
    if (tokenData && !tokenDataIsFetching) {
      setsponsorDetails(tokenData);
    }
  }, [data, isFetching, tokenData, tokenDataIsFetching]);

  return (
    <Box>
      <Typography variant='h5' className='text-white font-bold mb-5'>
        Sponsor Info
      </Typography>
      <Stack direction={'row'} className='mb-2'>
        <Typography className={className}>Sponsor Name</Typography>
        {isFetching || tokenDataIsFetching ? (
          <Skeleton variant='rectangular' className='w-full h-24' />
        ) : (
          <Typography>: {sponsorDetails?.sponsor.sponsor_name}</Typography>
        )}
      </Stack>
      <Stack direction={'row'} className='mb-2'>
        <Typography className={className}>Main Contact</Typography>
        {isFetching || tokenDataIsFetching ? (
          <Skeleton variant='rectangular' className='w-full h-24' />
        ) : (
          <Typography>: {sponsorDetails?.sponsor.main_contact}</Typography>
        )}
      </Stack>
      <Stack direction={'row'} className='mb-2'>
        <Typography className={className}>Email</Typography>
        {isFetching || tokenDataIsFetching ? (
          <Skeleton variant='rectangular' className='w-full h-24' />
        ) : (
          <Typography>: {sponsorDetails?.sponsor.email}</Typography>
        )}
      </Stack>
      <Stack direction={'row'} className='mb-2'>
        <Typography className={className}>Phone Number</Typography>
        {isFetching || tokenDataIsFetching ? (
          <Skeleton variant='rectangular' className='w-full h-24' />
        ) : (
          <Typography>: {sponsorDetails?.sponsor.phone_number}</Typography>
        )}
      </Stack>
      {sponsorDetails?.start_date ? (
        <Stack direction={'row'} className='mb-2'>
          <Typography className={className}>Start Date</Typography>
          {isFetching || tokenDataIsFetching ? (
            <Skeleton variant='rectangular' className='w-full h-24' />
          ) : (
            <Typography>
              : {dayjs(sponsorDetails?.start_date).format('MM/DD/YYYY')}
            </Typography>
          )}
        </Stack>
      ) : null}
      {sponsorDetails?.end_date ? (
        <Stack direction={'row'}>
          <Typography className={className}>End Date</Typography>
          {isFetching || tokenDataIsFetching ? (
            <Skeleton variant='rectangular' className='w-full h-24' />
          ) : (
            <Typography>
              : {dayjs(sponsorDetails?.end_date).format('MM/DD/YYYY')}
            </Typography>
          )}
        </Stack>
      ) : null}
    </Box>
  );
};
