import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import axiosInstance from '../../axiosConfig';

export const getUsersByTaskApiPath = (taskId: number) => {
  return `v1/task/${taskId}/task-users`;
};

const getUsersByTaskList = (task_id: number) => {
  return axiosInstance
    .get(getUsersByTaskApiPath(task_id))
    .then(
      (
        response: AxiosResponse<IApiDataResponse<{id: number; name: string}[]>>,
      ) => {
        return response.data.data;
      },
    );
};

export const useRqUsersByTask = (task_id: number, enabled: boolean = true) => {
  return useQuery({
    queryKey: [getUsersByTaskApiPath(task_id)],
    queryFn: () => getUsersByTaskList(task_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(task_id) && enabled,
  });
};
