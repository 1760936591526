import dayjs from 'dayjs';
import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  StepperLayout,
  useStepperState,
} from '../../../components/layouts/stepper/StepperLayout';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {apiPost} from '../../../axiosConfig';
import {IScripts} from '../event-specific/EventSpecificStepper';
import StationsDropDown from '../../../components/models/stations/StationDropDown';
import {IStation} from '../../../dto/Station.dto';
import {ICustomLibrary} from '../../../dto/Message.dto';
import OndemandCustomLibrary from './OndemandCustomLibrary';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import OndemandExpiryDate from './OndemandExpiryDate';
import {OndemandReview} from './OndemandReview';
import MessageTrackUploadButton from '../MessageTrackSyncCmpt';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {isInternalSelector} from '../../../store/Slices/userSlice';
import {EsmMessages} from '../event-specific/EsmMessages';
import {StepperItemProps} from '../../../components/layouts/stepper/StepperLeftNavigation';
import {addNewTackToMessages} from '../event-specific/MessagesStateFunctionality';
import {useRqCustomLibrary} from '../../../react_query/custom_libarary/ListCustomLibraries';
import {onDemandGridApiPath} from '../../../react_query/messages/message-requests/OndemandRequestList';
import {useQueryClient} from '@tanstack/react-query';
import {checkMessagesValid} from '../../../Service/CommonService';

const defaultApiState = {
  status: '',
  error: null,
};

export type IOnDemCustLib = Pick<ICustomLibrary, 'id' | 'name'> & {
  station_name: string;
  new: boolean;
};

const OndemandStepper = ({handleClose}: {handleClose: () => void}) => {
  const queryClient = useQueryClient();
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const companyId = useSelector(companyIdSelector);
  const isStaff = useSelector(isInternalSelector);
  const [stations, setStations] = useState<IStation[]>([]);
  const [libraries, setLibraries] = useState<IOnDemCustLib[]>([]);
  const [isExpireDateSet, setIsExpireDateSet] = useState(false);
  const [expireDate, setExpireDate] = useState<string | null>(null);
  const [messages, setMessages] = useState<IScripts[]>([
    {
      id: Date.now(),
      uuid: uuidv4(),
      message: '',
      name: '',
      requestable_type: 'ondemand-message',
    },
  ]);
  const [selectedTracks, setSelecetedTracks] = useState<IMediaTrack[]>([]);
  const [selectedLibraries, setSelectedLibraries] = useState<IOnDemCustLib[]>(
    [],
  );
  const [apiState, setApiState] = useState(defaultApiState);

  const {
    data: customLib,
    isFetching: customLibIsFetching,
    isError: customLibIsError,
  } = useRqCustomLibrary(Number(companyId), {
    station_ids: stations.map(o => o.id),
  });

  const isEmptyMsgAvail = useMemo(() => {
    return checkMessagesValid(messages);
  }, [messages]);

  const isExpireDateNotValid = useMemo(() => {
    if (isExpireDateSet) {
      const today = dayjs().startOf('day');
      const endDate = dayjs(expireDate).startOf('day');
      return expireDate === null || !endDate.isAfter(today);
    }
    return false;
  }, [isExpireDateSet, expireDate]);

  const steps: StepperItemProps[] = useMemo(() => {
    const stepList: StepperItemProps[] = [
      {
        label: 'Select Options',
        disabled: false,
      },
      {
        label: 'Message Details',
        disabled:
          (!stations.length && !selectedLibraries.length) ||
          isExpireDateNotValid,
      },
      {
        label: 'Review',
        disabled: !isEmptyMsgAvail,
      },
    ];

    return stepList;
  }, [
    stations.length,
    selectedLibraries.length,
    isExpireDateNotValid,
    isEmptyMsgAvail,
  ]);

  const handleSubmit = useCallback(() => {
    const msgs = messages
      ? messages
          .filter(({message, name}) => message !== '' && name !== '')
          .map(({message, name, media_track}) => ({
            message,
            name,
            media_track_id: media_track?.id ?? null,
          }))
      : [];
    const playlist_names = selectedLibraries
      .filter(v => v.new === true)
      .map(v => v.name);
    const playlist_ids = selectedLibraries
      .filter(v => v.new === false)
      .map(v => v.id);
    const station_ids = stations.map(v => v.id);
    const end_date = expireDate ? dayjs(expireDate).format('YYYY-MM-DD') : '';
    const payload = {
      company_id: companyId,
      custom_library_ids: playlist_ids,
      station_ids: station_ids,
      custom_library_names: playlist_names,
      messages: msgs,
      is_expire_date_set: isExpireDateSet,
      expire_date: end_date,
    };
    apiPost(`/v1/company/${companyId}/message/ondemand-message`, payload)
      .then(_res => {
        queryClient.invalidateQueries({
          queryKey: [onDemandGridApiPath(companyId)],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [
    companyId,
    expireDate,
    isExpireDateSet,
    messages,
    selectedLibraries,
    stations,
    queryClient,
  ]);

  useEffect(() => {
    if (customLib && !customLibIsFetching && !customLibIsError) {
      setLibraries([
        {id: 0, name: 'Add New', station_name: ''} as IOnDemCustLib,
        ...(customLib.data.map(v => {
          return {...{id: v.id, name: v.name, station_name: ''}, new: false};
        }) as IOnDemCustLib[]),
      ]);
    }
  }, [customLib, customLibIsError, customLibIsFetching]);

  useEffect(() => {
    selectedTracks.forEach(track => {
      setMessages(messages =>
        addNewTackToMessages({
          messages,
          requestable_type: 'ondemand-message',
          track,
        }),
      );
    });
  }, [selectedTracks]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      isSubmitted={apiState.status !== ''}
      title='Create New Message'>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Options':
            return (
              <Box>
                <Typography variant='h5' className='mb-5'>
                  Select Options
                </Typography>
                <div
                  style={{
                    display: 'block',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '25px',
                  }}>
                  <StationsDropDown
                    stations={stations}
                    setStations={setStations}
                    fetchEnable={true}
                  />
                  <OndemandCustomLibrary
                    libraries={libraries}
                    selected={selectedLibraries}
                    setLibraries={setLibraries}
                    setSelected={setSelectedLibraries}
                  />
                  <OndemandExpiryDate
                    is_expire_date_set={isExpireDateSet}
                    expire_date={expireDate}
                    setIsExpireDateSet={setIsExpireDateSet}
                    setExpireDate={setExpireDate}
                    isToday={isExpireDateNotValid}
                  />
                </div>
              </Box>
            );
          case 'Message Details':
            return (
              <>
                {isStaff ? (
                  <MessageTrackUploadButton
                    type={'Choose OD Message'}
                    isSingleSongSelection={false}
                    selectedTracks={selectedTracks}
                    setSelecetedTracks={setSelecetedTracks}
                  />
                ) : null}

                <EsmMessages
                  messages={messages}
                  setMessages={setMessages}
                  setSelecetedTracks={setSelecetedTracks}
                  requestableType='ondemand-message'
                />
              </>
            );
          case 'Review':
            return (
              <OndemandReview
                stations={stations}
                libraries={selectedLibraries}
                is_expire_date_set={isExpireDateSet}
                expire_date={expireDate}
                messages={messages}
              />
            );
          default:
            return null;
        }
      })()}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          onEdit={() => setApiState(defaultApiState)}
          onRetry={handleSubmit}
          onClose={handleClose}
          apiState={apiState}
        />
      )}
    </StepperLayout>
  );
};

export default OndemandStepper;
