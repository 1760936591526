import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {apiGet} from '../../../axiosConfig';
import {ITimedMessageRequest} from '../../../dto/timed-message/TimedMessageRequest.dto';
import {ISongRequest} from '../../../dto/SongRequest.dto';
import {IMessage} from '../../../dto/Message.dto';
import {ITimedRequestDateRange} from '../../../dto/timed-message/TimedRequestDateRange.dto';
import {ITimedRequestDayTime} from '../../../dto/timed-message/TimedRequestDayTime.dto';
import {IStation} from '../../../dto/Station.dto';
import {IApiDataResponse} from '../../../dto/API.dto';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';

export type ITimedMessageDetailedResponse = ITimedMessageRequest & {
  stations: IStation[];
  name: string;
  day_times: ITimedRequestDayTime[];
  date_ranges: ITimedRequestDateRange[];
  messages: IMessage[];
  song_requests: ISongRequest[];
  timed_sequences: ITimedSequenceType;
};

export type ITimedSequenceType =
  | {
      id: number;
      sequenceable_type: 'message';
      sequenceable: IMessage & {media_track?: IMediaTrack};
    }[]
  | {
      id: number;
      sequenceable_type: 'song-request';
      sequenceable: ISongRequest & {media_track?: IMediaTrack};
    }[];

export const getViewTimedMessagesApiPath = (
  company_id: number,
  request_id: number,
) => {
  return `v1/company/${company_id}/message/timed-message/${request_id}`;
};

const getViewTimedMessages = (company_id: number, request_id: number) => {
  return apiGet(getViewTimedMessagesApiPath(company_id, request_id)).then(
    (
      response: AxiosResponse<IApiDataResponse<ITimedMessageDetailedResponse>>,
    ) => {
      return response.data.data;
    },
  );
};

export const useRqViewTimedMessagesByRequestID = (
  company_id: number,
  request_id: number,
  enabled: boolean = true,
) => {
  const enableRender = company_id > 0 ? enabled : false;
  const resultVal = useQuery({
    queryKey: [getViewTimedMessagesApiPath(company_id, request_id)],
    queryFn: () => getViewTimedMessages(company_id, request_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enableRender,
  });
  return resultVal;
};
