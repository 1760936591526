import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useCallback, useMemo, useState} from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import {Box, Stack} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import {GridCellExpand} from '../../../components/utlis/DataGrid/ExpandCellRender';
import {IDataGridSearchSort} from '../../../dto/Html.dto';
import {useRqStingerMessageList} from '../../../react_query/messages/message-requests/StingerMessageList';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import CustomNoRowsOverlay from '../../../components/utlis/DataGrid/CustomNoRowsOverlay';
import IconAdd from '../../../asset/icons/icon_messaging.svg';
import InputSearch from '../../../components/styles/InputSearch';
import {GridDurationColumn} from '../../../components/grid/GridColumns';
import {UserCanAny} from '../../../components/UserCan';
import RestoreButton from '../../../components/Button/RestoreButton';
import DeactivateButton from '../../../components/Button/DeactivateButton';
import {apiPut} from '../../../axiosConfig';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import ActiveDropdown from '../../../components/dropdowns/ActiveDropdown';
import {IEsmStatus} from '../../../dto/EventSpecificMessageRequest.dto';
import CommonDialog from '../../../components/CommonDialog';
import {useUserCanAny} from '../../../hooks/useUserCan';

const StingersDataGrid = () => {
  const companyId = useSelector(companyIdSelector);
  const [status, setStatus] = useState<IEsmStatus>('Active');
  const canUpdateStatus = useUserCanAny(
    'is-admin,stinger-message:update-status',
  );

  const [query, setQuery] = useState<IDataGridSearchSort>({
    search: '',
    page: 0,
    pageSize: 10,
    status: 'Active',
  });

  const {data, isLoading} = useRqStingerMessageList(companyId, query);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'url',
        type: 'actions',
        headerName: 'Play',
        width: 54,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.url ? (
            <MusicPreviewBtn id={params.row.id} url={params.row.url} />
          ) : null;
        },
      },
      {...GridDurationColumn, sortable: false},
      {
        field: 'name',
        headerName: 'Message Name',
        hideable: false,
        width: 180,
      },
      {
        field: 'script',
        headerName: 'Script',
        hideable: false,
        sortable: false,
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
          <GridCellExpand
            value={params.row.script}
            width={params.colDef.computedWidth}
          />
        ),
      },
      {
        field: 'created_at',
        headerName: 'Created Date',
        hideable: false,
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return dayjs(params.value).format('MM/DD/YYYY hh:mm:ss A');
        },
      },
      {
        field: 'created_by',
        headerName: 'Created By',
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <GridCellExpand
            value={params.row.created_by.name}
            width={params.colDef.computedWidth}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Action',
        width: 150,
        sortable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <ActionDetails params={params} status={status} />
        ),
      },
    ],
    [status],
  );

  const handleSearch = useCallback((val: string) => {
    setQuery(prev => ({
      ...prev,
      search: val,
    }));
  }, []);

  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    if (model[0]) {
      setQuery(prev => ({...prev, ...model[0]}));
    }
  };

  const handleStatus = (newValue: IEsmStatus) => {
    setStatus(newValue);
    setQuery(prev => {
      return {
        ...prev,
        status: newValue,
      };
    });
  };

  return (
    <Box className='GridTableList'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className='max-h-40 mt-5'>
        <ActiveDropdown value={status} onChange={handleStatus} />
        <InputSearch
          className='max-w-347 block my-3'
          placeholder='Search'
          value={`${query.search}`}
          onChange={handleSearch}
        />
      </Stack>

      <Box sx={{height: 'calc(100vh - 250px)'}}>
        <DataGrid
          disableColumnMenu
          disableColumnSelector
          className='custom-data-grid cmc_new_grid h-654 text-white'
          rows={data?.data ?? []}
          columns={columns}
          loading={isLoading}
          rowCount={data?.meta?.total ?? 0}
          paginationModel={{
            page: query.page,
            pageSize: query.pageSize,
          }}
          pageSizeOptions={[10, 25, 50]}
          paginationMode='server'
          onPaginationModelChange={handlePagination}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          disableRowSelectionOnClick
          columnVisibilityModel={{
            actions: canUpdateStatus,
          }}
          slots={{
            pagination: DataGridCustPagination,
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                imageSrc={IconAdd}
                message='No stinger messages available'
              />
            ),
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default StingersDataGrid;

const ActionDetails = ({
  params,
  status,
}: {
  params: GridRenderCellParams;
  status: IEsmStatus;
}) => {
  const queryClient = useQueryClient();
  const companyId = useSelector(companyIdSelector);
  const [open, setOpen] = useState(false);
  const [apiState, setApiState] = useState({status: '', error: null});

  const handleSubmit = useCallback(() => {
    setApiState({
      status: 'pending',
      error: null,
    });
    apiPut(
      `/v1/company/${companyId}/message/stinger-message/${params.row.id}/status`,
    )
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [`v1/company/${companyId}/message/stinger-message`],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  }, [companyId, params.row.id, queryClient]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setApiState({
      status: '',
      error: null,
    });
    setOpen(false);
  };

  return (
    <>
      {params.row.status !== 'In Progress' ? (
        <UserCanAny scope='is-admin,stinger-message:update-status'>
          {status === 'Inactive' ? (
            <RestoreButton onClick={handleOpen} />
          ) : (
            <DeactivateButton onClick={handleOpen} />
          )}
        </UserCanAny>
      ) : null}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleClose}
          onClose={handleClose}
        />
      )}
      {open && (
        <CommonDialog
          open={open}
          status={status === 'Active' ? true : false}
          handleSubmit={handleSubmit}
          handleCancel={handleClose}
        />
      )}
    </>
  );
};
