import {Dayjs} from 'dayjs';
import {useMemo} from 'react';
import {Grid, Stack, Typography} from '@mui/material';
import {iTimedMsgTimesItem} from '../times/TimedMsgRequestTimesCard';
import {iTimedMsgTimeRange} from '../times/TimedMsgRequestTimeRangePicker';

type Props = {
  items: iTimedMsgTimesItem[];
};

const TimedMsgRequestReviewDayTimes = ({items}: Props) => {
  return (
    <Grid container spacing={2}>
      {items.map(item => (
        <Grid item md={12} key={item.id}>
          <Stack direction='row' spacing={2} alignItems='flex-start'>
            <Typography
              sx={{textTransform: 'capitalize', whiteSpace: 'pre-wrap'}}
              id={`day-time-${item.id}`}>
              {item.selectedDays.join('\n')}
            </Typography>
            <TimesListOfTimedMessage times={item.timeRanges} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};
export default TimedMsgRequestReviewDayTimes;

const TimesListOfTimedMessage = ({times}: {times: iTimedMsgTimeRange[]}) => {
  const timesList = useMemo(() => {
    const timesArr: Dayjs[] = [];
    times.forEach(o => {
      if (o.start_time) {
        timesArr.push(o.start_time);
      }
    });
    timesArr.sort((a, b) => {
      return a.isAfter(b) ? 1 : -1;
    });
    return timesArr.map(time => time.format('hh:mm A'));
  }, [times]);

  return (
    <Typography variant='body2' sx={{whiteSpace: 'pre-wrap'}}>
      {timesList.join(', ')}
    </Typography>
  );
};
