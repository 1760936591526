import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Button, Typography} from '@mui/material';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import BaseCreateSeasonDialog from './BaseCreateSeasonDialog';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {
  companyIdSelector,
  isNRorSZSelector,
} from '../../../../store/Slices/companySlice';
import ExistingCampaignChooserBtn from '../../../sponsor/ExistingCampaignChooserBtn';
import {useStoreSelector} from '../../../../store/configstore';

const BaseSeasonAndTracksAdd = ({
  title,
  selectedTracks,
  setSelectedTracks,
}: {
  title: string;
  selectedTracks?: IMediaTrack[];
  setSelectedTracks?: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
}) => {
  const isStaff = useSelector(isInternalSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const companyId = useStoreSelector(companyIdSelector);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSeason = () => {
    setOpen(true);
  };

  return (
    <Box className='w-full flex flex-row justify-between mb-2'>
      <Typography className='text-white text-18'>{title}</Typography>
      <Box className='flex'>
        {isNRSZ && (
          <Button variant='outlined' className='mr-3' onClick={handleSeason}>
            Create New Season
          </Button>
        )}
        {isStaff && selectedTracks && setSelectedTracks ? (
          <ExistingCampaignChooserBtn
            tracks={selectedTracks}
            onChange={setSelectedTracks}
            type='base-message'
            label='Choose Base Message'
            max={20}
            companyId={companyId}
          />
        ) : null}
      </Box>
      <BaseCreateSeasonDialog
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
      />
    </Box>
  );
};

export default BaseSeasonAndTracksAdd;
