import {v4 as uuidv4} from 'uuid';
import {IScripts} from './EsmMessages';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {IMessageType} from '../../../dto/Message.dto';

type K = keyof IScripts;

export const updateMessages = (options: {
  messages: IScripts[];
  uuid: string;
  key: K;
  value: IScripts[K];
}) => {
  const {messages, uuid, key, value} = options;
  return messages.map(message => {
    if (message.uuid === uuid) {
      return {
        ...message,
        [key]: value,
      };
    }
    return message;
  });
};

export const addNewTackToMessages = (options: {
  messages: IScripts[];
  track: IMediaTrack;
  requestable_type: IMessageType;
}) => {
  const {messages, track, requestable_type} = options;
  if (!messages.some(val => val.media_track?.id === track.id)) {
    const filteredPrevData = messages.filter(val => {
      return (
        val.message.length !== 0 || val.name.length !== 0 || val.media_track
      );
    });
    return [
      {
        id: Date.now(),
        uuid: uuidv4(),
        message: track.track_name,
        name: '',
        requestable_type,
        media_track: track,
        events: [],
        seasons: [],
        isAllSeason: true,
      },
      ...filteredPrevData,
    ];
  }
  return messages;
};
