import {useState} from 'react';
import {IconButton} from '@mui/material';
import {AutoMode} from '@mui/icons-material';
import {v4 as uuidv4} from 'uuid';
import EventSpecificStepper from '../../../messages/event-specific/EventSpecificStepper';
import {useRqViewBaseListMessagesByRequestID} from '../../../../react_query/messages/MessagesByCmpId';
import {companyIdSelector} from '../../../../store/Slices/companySlice';
import {useStoreSelector} from '../../../../store/configstore';

const BaseMsgToEventMsg = ({
  id,
  handleClose,
}: {
  id: number;
  handleClose: () => void;
}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const {data} = useRqViewBaseListMessagesByRequestID(companyId, Number(id));
  return data?.message ? (
    <EventSpecificStepper
      handleClose={handleClose}
      base_request_id={id}
      defaultMessages={[
        {
          id: 0,
          uuid: uuidv4(),
          message: data.message.message,
          name: data.name || '',
          requestable_type: 'event-message',
          media_track: data.message.media_track,
        },
      ]}
    />
  ) : null;
};

const BaseMsgConvertToEventMsgButton = ({id}: {id: number}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label='edit'
        color='success'
        title='Convert to Event'
        onClick={() => setOpen(true)}>
        <AutoMode />
      </IconButton>
      {open && <BaseMsgToEventMsg id={id} handleClose={handleClose} />}
    </>
  );
};

export default BaseMsgConvertToEventMsgButton;
