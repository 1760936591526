import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import axiosInstance, {apiGet} from '../axiosConfig';
import {IApiDataResponse, IApiPaginationResponse} from '../dto/API.dto';
import {ICompany, ICompanyDetails} from '../dto/Company.dto';

export const apiGetCompanies = (params: ICompanyQueryParams) => {
  return apiGet(`/v1/company`, {params: params}).then(
    (response: AxiosResponse<IApiDataResponse<ICompany[]>>) =>
      response.data.data,
  );
};

export const apiGetCompany = (companyId: number) => {
  return apiGet(`/v1/company/${companyId}`).then(
    (res: AxiosResponse<IApiDataResponse<ICompanyDetails>>) => res.data.data,
  );
};

export const useRqGetCompany = (companyId: number, enabled: boolean = true) => {
  return useQuery({
    queryKey: [`/v1/company/${companyId}`],
    queryFn: () => apiGetCompany(companyId),
    staleTime: 600000,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const useRqGetCompanies = (
  params: ICompanyQueryParams,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['api/v1/company', params],
    queryFn: () => apiGetCompanies(params),
    staleTime: 600000,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export type ICompanyQueryParams = {
  search?: string;
  product_respresentive?: number[];
  psm?: number[];
  product_id?: number[];
  cmc_product?: string[];
  product_type?: string[];
  secondary_product_type?: string[];
  level_product_type?: string[];
  state?: number[];
  sort_field?: string;
  sort_order?: string;
};

export const apiPaginateCompanies = (params: ICompaniesQueryParmas) => {
  return apiGet(`/v1/companies`, {params: params}).then(
    (response: AxiosResponse<IApiPaginationResponse<ICompanyDetails[]>>) =>
      response.data,
  );
};

export const useRqPaginateCompanies = (
  params: ICompaniesQueryParmas,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['api/v1/companies', params],
    queryFn: () => apiPaginateCompanies(params),
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, // 1 hr
    retry: false,
    enabled: enabled,
  });
};

export const apiPaginateCompaniesGlobal = (params: ICompaniesQueryParmas) => {
  return apiGet(`/v1/companies/global`, {params: params}).then(
    (response: AxiosResponse<IApiPaginationResponse<ICompany[]>>) =>
      response.data,
  );
};

export const useRqPaginateCompaniesGlobal = (
  params: ICompaniesQueryParmas,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ['api/v1/companies/global', params],
    queryFn: () => apiPaginateCompaniesGlobal(params),
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, // 1 hr
    retry: false,
    enabled: enabled,
  });
};

export type ICompaniesQueryParmas = ICompanyQueryParams & {
  page: number;
  per_page: number;
};

// Bill details
export type PricingDetail = {
  name: string;
  count: number;
  price: number;
};
export type BillDetailsProp = {
  product: {
    cmc_product: string;
    primary: string;
  };
  level: PricingDetail;
  deleted_full_station: PricingDetail;
  deleted_mirror_station: PricingDetail;
  deleted_odo_station: PricingDetail;
  updated_full_station: PricingDetail;
  updated_mirror_station: PricingDetail;
  updated_odo_station: PricingDetail;
  full_station: PricingDetail;
  odo_station: PricingDetail;
  mirror_station: PricingDetail;
  setup: PricingDetail;
  discount: PricingDetail;
  service_total: PricingDetail;
  laptop: PricingDetail;
  total_invoice: PricingDetail;
  previous_total: PricingDetail;
  grand_total: PricingDetail;
  note?: string;
};
export const getCompanyBillApiPath = (companyId: number) =>
  `/v1/company/${companyId}/add-on`;
const getCompanyBill = (companyId: number) => {
  return axiosInstance
    .get(getCompanyBillApiPath(companyId))
    .then((response: AxiosResponse<IApiDataResponse<BillDetailsProp>>) => {
      return response.data.data;
    });
};

export const useRqCompanyBill = (
  companyId: number,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [getCompanyBillApiPath(companyId)],
    queryFn: () => getCompanyBill(companyId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
