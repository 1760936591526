import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';

interface SongSelectionRemoveButtonProps extends IconButtonProps {
  onClick: () => void;
}

export const SongSelectionRemoveButton = ({
  onClick,
  ...props
}: SongSelectionRemoveButtonProps) => {
  return (
    <IconButton
      {...props}
      onClick={onClick}
      aria-label='delete'
      color='success'>
      <DoneIcon />
    </IconButton>
  );
};
