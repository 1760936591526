import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {RequestTheme} from '../Theme';
import StepperButtons from './StepperButtons';
import StepperLeftNavigation, {StepperItemProps} from './StepperLeftNavigation';
import backIcon from '../../../asset/icons/icon_back.svg';
import {DialogClose} from '../../dialogs/DialogFrame/DialogClose';

type StepperProps = {
  defaultFirstStep?: number;
  steps: StepperItemProps[];
  nextLabel?: string;
  activeStepIndex: number;
  onBack: () => void;
  onNext: () => void;
  onStepClick: (index: number) => void;
  onCancel: () => void;
  onSubmit: () => void;
  addBtn?: {
    label: string;
    onClick: () => void;
  };
  children: React.ReactNode;
  disabled?: boolean; // Disable navigation,back button and next button
  isSubmitted: boolean; // Disable navigation and hide the back and next buttons
  loading?: boolean; // Show loader inside next button
  fullWidth?: boolean;
  isLastHide?: boolean;
  title?: string;
  footerLabel?: string;
  setClose?: () => void;
  hideButton?: boolean;
  noPadding?: boolean;
  maxWidth?: number;
  hideCloseButton?: boolean;
};

export const useStepperState = (defaultStep: number) => {
  const [activeStepIndex, setActiveStepIndex] = useState(defaultStep);
  const onNextStep = useCallback(() => {
    setActiveStepIndex(prevActiveStep => prevActiveStep + 1);
  }, []);
  const onPreviousStep = useCallback(() => {
    setActiveStepIndex(prevActiveStep => prevActiveStep - 1);
  }, []);

  const onStepClick = useCallback((index: number) => {
    setActiveStepIndex(index);
  }, []);
  return {
    activeStepIndex,
    setActiveStepIndex,
    onNextStep,
    onPreviousStep: onPreviousStep,
    onStepClick,
  };
};

export const StepperLayout = ({
  defaultFirstStep,
  steps,
  activeStepIndex,
  nextLabel,
  children,
  onBack,
  onNext,
  onStepClick,
  onCancel,
  onSubmit,
  addBtn,
  disabled,
  isSubmitted,
  loading,
  isLastHide,
  title,
  footerLabel,
  hideButton,
  hideCloseButton,
  maxWidth,
}: StepperProps) => {
  const isFirstStep =
    activeStepIndex === (defaultFirstStep ? defaultFirstStep : 0);
  const isLastStep = activeStepIndex === steps.length - 1;
  const isReviewDisabled = steps[activeStepIndex]?.isReviewDisabled ?? false;
  const isNextDisabled = isLastStep
    ? isReviewDisabled
    : steps[activeStepIndex + 1]?.disabled;
  const [confirmCloseDialog, setConfirmCloseDialog] = useState(false);

  const confirmClose = useCallback(() => {
    setConfirmCloseDialog(true);
  }, []);

  useEffect(() => {
    const handleBackButton = (_e: PopStateEvent) => {
      setConfirmCloseDialog(true);
      window.history.pushState(null, document.title, window.location.href);
    };
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <Dialog
      maxWidth={isLastHide && isLastStep ? 'lg' : 'xl'}
      fullWidth
      open={true}
      onClose={confirmClose}
      aria-labelledby='stepper-dialog-title'
      aria-describedby='stepper-dialog-description'
      PaperProps={{
        sx: {maxWidth: maxWidth},
      }}>
      <ThemeProvider theme={RequestTheme}>
        <DialogTitle>
          {isLastStep && isLastHide ? (
            <IconButton
              title='Close'
              className='w-23 h-18 ml-9 mt-7 mr-4 cursor-pointer'
              onClick={onBack}>
              <img src={backIcon} alt='Asset is loading..' />
            </IconButton>
          ) : null}
          <Typography
            className={`text-white ${isLastStep && isLastHide ? '' : 'ml-9'}`}>
            {isLastStep && isLastHide
              ? 'Preview'
              : title && title.length
              ? title
              : ''}
          </Typography>
          {hideCloseButton && hideCloseButton === true ? null : (
            <DialogClose onClose={confirmClose} />
          )}
        </DialogTitle>

        <DialogContent sx={{padding: '0 !important'}}>
          <Box sx={{display: 'flex', height: '100%'}}>
            {confirmCloseDialog && !hideCloseButton ? (
              <BackNavigationConfirmDialog
                setDialog={setConfirmCloseDialog}
                closeStepper={onCancel}
              />
            ) : null}
            {isLastHide && isLastStep ? null : (
              <StepperLeftNavigation
                steps={steps}
                activeStepIndex={activeStepIndex}
                onStepClick={onStepClick}
                disabled={disabled || isSubmitted}
              />
            )}
            <Box className='flex-column w-full'>
              <Box className='h-full p-7 overflow-auto scrollbar'>
                {children}
              </Box>
              {!isSubmitted && !hideButton && (
                <DialogActions>
                  <StepperButtons
                    nextLabel={nextLabel}
                    nextDisabled={isNextDisabled}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    onBack={isFirstStep ? onCancel : onBack}
                    onNext={isLastStep ? onSubmit : onNext}
                    addBtn={addBtn}
                    disabled={disabled}
                    loading={loading}
                    footerLabel={footerLabel}
                  />
                </DialogActions>
              )}
            </Box>
          </Box>
        </DialogContent>
      </ThemeProvider>
    </Dialog>
  );
};

const BackNavigationConfirmDialog = (props: {
  setDialog: (val: boolean) => void;
  closeStepper: () => void;
}) => {
  const handleClose = useCallback(() => {
    props.setDialog(false);
  }, [props]);

  return (
    <Dialog
      open
      maxWidth='xs'
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent className='px-10 py-8 text-center'>
        <Typography>The back button will reset the create workflow</Typography>
        <Stack
          className='d-flex align-center justify-center pt-5'
          direction='row'
          spacing={3}>
          <Button variant='contained' onClick={props.closeStepper}>
            Agree
          </Button>
          <Button variant='outlined' onClick={handleClose}>
            Close
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
