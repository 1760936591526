import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import {apiGet} from '../../axiosConfig';
import {IMessage} from '../../dto/Message.dto';
import {ICompanySchool} from '../../dto/CompanySchool.dto';
import {IStation} from '../../dto/Station.dto';
import {IOdmStatus} from '../../dto/OndemandMessageRequest.dto';
import {IOnDemCustLib} from '../../pages/messages/on-demand-msg/OndemandStepper';

type IOndemandRequestResponse = {
  custom_library_request: IOnDemCustLib[];
  expire_date: string;
  name: string;
  id: number;
  status: IOdmStatus;
  is_expire_date_set: number;
  messages?: IMessage[];
  schools?: ICompanySchool[];
  stations?: IStation[];
  voice_artists?: string[];
};

const getOndemandMsgRequestView = (
  companyId: number,
  ondemandReqId: number,
) => {
  return apiGet(
    `v1/company/${companyId}/message/ondemand-message/${ondemandReqId}`,
  ).then(
    (response: AxiosResponse<IApiDataResponse<IOndemandRequestResponse>>) => {
      return response.data.data;
    },
  );
};

export const useRqOndemandMsgRequestView = (
  companyId: number,
  ondemandReqId: number,
  enabled: boolean = true,
) => {
  const resultVal = useQuery({
    queryKey: [
      `api/v1/company/${companyId}/message/ondemand-message`,
      ondemandReqId,
    ],
    queryFn: () => getOndemandMsgRequestView(companyId, ondemandReqId),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
  return resultVal;
};
