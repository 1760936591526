import {Dispatch, SetStateAction} from 'react';
import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {iTimedMessageMessageItem} from './TimedMsgRequestContentMessageCard';
import {ITimedMessageMsgType} from '../../../../../dto/timed-message/TimedMessageRequest.dto';

type Props = {
  type: string;
  isCopyForm?: boolean;
  onChange: Dispatch<SetStateAction<ITimedMessageMsgType>>;
  setMessages: Dispatch<SetStateAction<iTimedMessageMessageItem[]>>;
};

const TimedMsgRequestContentTypeSwitchForm = ({
  type,
  onChange,
  setMessages,
  isCopyForm,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessageType = e.target.value;
    let defaultMessages: iTimedMessageMessageItem[] = [
      {
        id: Date.now(),
        type: newMessageType as 'message' | 'song',
        message: '',
        artist: '',
        title: '',
        label: newMessageType === 'message' ? 'Message' : 'Song',
      },
    ];
    if (newMessageType === 'sequence') {
      defaultMessages = [];
    }
    setMessages(defaultMessages);
    onChange(e.target.value as ITimedMessageMsgType);
  };
  return (
    <>
      <Typography variant='h5' className='my-5 text-white-50'>
        Select Message Type
      </Typography>
      <RadioGroup
        name='message_type'
        value={type}
        onChange={handleChange}
        className='flex-row'>
        <FormControlLabel
          value='message'
          control={
            <Radio disabled={isCopyForm} sx={{opacity: isCopyForm ? 0.5 : 1}} />
          }
          label='Timed Message'
        />
        <FormControlLabel
          value='song'
          control={
            <Radio disabled={isCopyForm} sx={{opacity: isCopyForm ? 0.5 : 1}} />
          }
          label='Timed Song'
        />
        <FormControlLabel
          value='sequence'
          control={
            <Radio disabled={isCopyForm} sx={{opacity: isCopyForm ? 0.5 : 1}} />
          }
          label='Timed Sequence'
          checked={type === 'sequence'}
        />
      </RadioGroup>
    </>
  );
};

export default TimedMsgRequestContentTypeSwitchForm;
