import {useEffect} from 'react';
import {Autocomplete, Checkbox, TextField, Typography} from '@mui/material';
import {useStoreSelector} from '../../../store/configstore';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {IStation} from '../../../dto/Station.dto';
import {useRqListCompanyStations} from '../../../react_query/stations/CompanyStationList';
import CommonCusDropDown from '../../../pages/messages/on-demand-msg/CommonCusDropDown';

const selectAll = {
  id: 0,
  name: 'Select All',
  label: 'Select All',
} as IStation;

const StationsDropDown = ({
  stations,
  setStations,
  fetchEnable = true,
}: {
  stations: IStation[];
  setStations: React.Dispatch<React.SetStateAction<IStation[]>>;
  fetchEnable: boolean;
}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const {data, isFetching, isError} = useRqListCompanyStations(
    Number(companyId),
    fetchEnable,
  );

  useEffect(() => {
    if (data && !isFetching && !isError && data.length === 1) {
      setStations([data[0] as IStation]);
    }
  }, [data, isError, isFetching, setStations]);

  const handleCancelStation = (StationId: number) => {
    setStations(prevStations =>
      prevStations.filter(Station => Station.id !== StationId),
    );
  };

  const deselectAllStations = () => {
    setStations([]);
  };

  return (
    <>
      {!isFetching && !isError && data ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id='stations'
          sx={{
            width: 300,
            '.MuiInputBase-root .MuiButtonBase-root': {display: 'none'},
          }}
          onChange={(_, values) => {
            const result = values.some(obj => obj.id === 0);
            setStations(result ? data : values);
          }}
          value={stations}
          options={[selectAll, ...data]}
          getOptionLabel={(option: IStation) => option.name}
          className='h-55 max-w-300 w-full m-0 mr-10 overflow-hidden custDpIcon'
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField {...params} placeholder='Select Stations' />
          )}
          renderOption={(props, option, {selected}) => (
            <li
              {...props}
              className='autoselect-option options-select flex items-center pl-6 py-2 cursor-pointer'>
              <Typography
                component='h6'
                className='flex items-center
           '>
                <Checkbox
                  checked={selected}
                  sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
                />
                {option.name}
              </Typography>
            </li>
          )}
        />
      ) : null}
      {stations.length > 0 && (
        <CommonCusDropDown
          showData={stations}
          cancelData={handleCancelStation}
          deselectAll={deselectAllStations}
        />
      )}
    </>
  );
};

export default StationsDropDown;
