import dayjs from 'dayjs';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, Grid, TextField, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {
  StepperLayout,
  useStepperState,
} from '../../../../components/layouts/stepper/StepperLayout';
import {IStation} from '../../../../dto/Station.dto';
import TimedMsgRequestContentTypeSwitchForm from './contents/TimedMsgRequestContentTypeSwitchForm';
import TimedMsgRequestContents from './contents/TimedMsgRequestContents';
import {iTimedMessageMessageItem} from './contents/TimedMsgRequestContentMessageCard';
import TimedMsgRequestDays from './days/TimedMsgRequestDays';
import TimedMsgRequestTimes from './times/TimedMsgRequestTimes';
import {iTimedMsgTimesItem} from './times/TimedMsgRequestTimesCard';
import TimedMsgRequestReview, {
  TimedMessageDetails,
} from './review/TimedMsgRequestReview';
import {apiPost} from '../../../../axiosConfig';
import {useStoreSelector} from '../../../../store/configstore';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import {
  ITimedMessageDayType,
  ITimedMessageMsgType,
} from '../../../../dto/timed-message/TimedMessageRequest.dto';
import {companyIdSelector} from '../../../../store/Slices/companySlice';
import StepperCard from '../../../../components/layouts/stepper/StepperCard';
import StationsDropDown from '../../../../components/models/stations/StationDropDown';
import {queryKeyMessageRequestList} from '../../../../react_query/messages/MessagesByCmpId';
import {isValidTimedMsgDates} from './TimedMsgFunctions';
import {getTimedMsgCalendarApiPath} from '../../../../react_query/messages/timed-messages/TimedMsgCalendar';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {GetDaysFromMultipleDateRange} from '../../../../components/utlis/date-time/GetDaysInDateRange';
import {useRqTimedMsgDateTimeExistence} from '../../../../react_query/messages/timed-messages/TimedMsgDateTimeExistence';

type Props = {
  defaultName: string;
  defaultStep: number;
  defaultStations: IStation[];
  defaultMessages: iTimedMessageMessageItem[];
  defaultDayType: ITimedMessageDayType;
  defaultDateRanges: iTimedMsgDateRange[];
  defaultTimeItems: iTimedMsgTimesItem[];
  defaultContentType: ITimedMessageMsgType;
  onClose: () => void;
  restoreId?: number;
  isCopyForm?: boolean;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
};

export type ITimedDuplicateTimes = {
  days: string[];
  times: string[];
};

export interface iTimedMsgDateRange {
  id: number;
  start_date: dayjs.Dayjs | null;
  end_date: dayjs.Dayjs | null;
}

const TimedMessageRequest = ({
  defaultName,
  defaultStep,
  defaultStations,
  defaultMessages,
  defaultDayType,
  defaultDateRanges,
  defaultTimeItems,
  defaultContentType,
  onClose,
  restoreId,
  isCopyForm,
  setStatus,
}: Props) => {
  const isStaff = useSelector(isInternalSelector);
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(defaultStep);
  const [selectedStations, setSelectedStations] =
    useState<IStation[]>(defaultStations);
  const [messages, setMessages] =
    useState<iTimedMessageMessageItem[]>(defaultMessages);
  const [contentsType, setContentsType] =
    useState<ITimedMessageMsgType>(defaultContentType);
  const [dayType, setDayType] = useState(defaultDayType);
  const [dateRanges, setDateRanges] = useState(defaultDateRanges);
  const [daysTimes, setDaysTimes] = useState(defaultTimeItems);
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
  const [apiState, setApiState] = useState({status: '', error: null});
  const companyId = useStoreSelector(companyIdSelector);
  const [messageName, setMessageName] = useState<string>(defaultName);
  const [footerText, setFooterText] = useState<string>('');
  const [duplicateTimes, setDuplicateTimes] = useState<ITimedDuplicateTimes[]>(
    [],
  );
  const [enableValidateApi, setEnableValidateApi] = useState(false);
  const queryClient = useQueryClient();

  const handleTypeChange = useCallback((newDayType: ITimedMessageDayType) => {
    setDayType(newDayType);
    setDateRanges([
      {
        id: Date.now(),
        start_date: null,
        end_date: null,
      } as iTimedMsgDateRange,
    ]);
    setDaysTimes([
      {
        id: Date.now(),
        selectedDays: [],
        timeRanges: [],
      },
    ]);
  }, []);
  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null});
    const fd = new FormData();
    selectedStations.forEach((station, i) =>
      fd.append(`stations[${i}]`, station.id.toString()),
    );
    fd.set('message_type', contentsType);
    fd.set('message_name', messageName);
    messages.forEach((item, i) => {
      fd.append(`sequence[${i}][type]`, item.type);
      if (item.type === 'song') {
        if (item.file) {
          fd.set(`sequence[${i}][type]`, 'file');
          fd.append(`sequence[${i}][file]`, item.file);
        } else if (item.track) {
          fd.append(`sequence[${i}][track_id]`, item.track.id.toString());
        } else {
          fd.append(`sequence[${i}][artist]`, item.artist);
          fd.append(`sequence[${i}][title]`, item.title);
        }
      } else {
        fd.append(`sequence[${i}][message]`, item.message);
        fd.append(
          `sequence[${i}][track_id]`,
          item.track ? item.track.id.toString() : '0',
        );
      }
    });
    fd.set('day_type', dayType);
    dateRanges.forEach((range, i) => {
      fd.append(
        `dates[${i}][start_date]`,
        range.start_date ? range.start_date.format('YYYY-MM-DD') : 'null',
      );
      fd.append(
        `dates[${i}][end_date]`,
        range.end_date ? range.end_date.format('YYYY-MM-DD') : 'null',
      );
    });
    daysTimes.forEach((item, i) => {
      item.selectedDays.forEach((day, j) => {
        fd.append(`day_times[${i}][days][${j}]`, day.toLowerCase());
      });
      item.timeRanges.forEach((range, j) => {
        fd.append(
          `day_times[${i}][times][${j}]`,
          range.start_time ? range.start_time.format('HH:mm:00') : 'null',
        );
      });
    });
    apiPost(`/v1/company/${companyId}/message/timed-message`, fd)
      .then(() => {
        setStatus('Active');
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'timed-message')],
          refetchType: 'active',
        });
        queryClient.invalidateQueries({
          queryKey: [getTimedMsgCalendarApiPath(companyId)],
          refetchType: 'active',
        });
        setApiState({status: 'success', error: null});
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
    return 1;
  }, [
    selectedStations,
    contentsType,
    messageName,
    messages,
    dayType,
    dateRanges,
    daysTimes,
    companyId,
    queryClient,
    setStatus,
  ]);

  const handleRestore = useCallback(() => {
    setApiState({status: 'pending', error: null});
    const dayTimes = daysTimes.map(o => ({
      days: o.selectedDays.map(day => day.toLowerCase()),
      times: o.timeRanges.map(o =>
        o.start_time ? o.start_time.format('HH:mm:00') : null,
      ),
    }));
    const payload = {
      type: 'Active',
      day_type: dayType,
      day_times: dayTimes,
      dates: dateRanges.map(({start_date, end_date}) => ({
        start_date: start_date ? start_date.format('YYYY-MM-DD') : null,
        end_date: end_date ? end_date.format('YYYY-MM-DD') : null,
      })),
    };
    apiPost(
      `v1/company/${companyId}/message/timed-message/${restoreId}?_method=PUT`,
      payload,
    )
      .then(() => {
        setApiState({status: 'success', error: null});
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'timed-message')],
          refetchType: 'active',
        });
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [companyId, dateRanges, dayType, daysTimes, queryClient, restoreId]);

  // option to edit the form when api is failed
  const handleEdit = useCallback(() => {
    setApiState({status: '', error: null});
  }, []);
  const isStationSelected = Boolean(selectedStations.length);
  const isMessageNameGiven =
    messageName?.trim().length > 0 && messageName?.trim().length <= 50;
  const isDaySelectionEnabled = useCallback(() => {
    return messages.every(message => {
      if (message.type === 'message') {
        return (
          message.message.replaceAll('\n', '').trim().length >= 10 ||
          message.track
        );
      }
      if (message.track || message.file) return true;
      return (
        message.artist.trim() &&
        !message.artistErrorMsg &&
        message.title.trim() &&
        !message.titleErrorMsg
      );
    });
  }, [messages]);

  const isTimeSelectionEnabled = useMemo(() => {
    return isValidTimedMsgDates(dateRanges, dayType, isStaff);
  }, [dayType, dateRanges, isStaff]);

  const isReviewEnabled = useCallback(() => {
    if (
      dayType === 'one-day' &&
      !GetDaysFromMultipleDateRange(dateRanges).every(d =>
        selectedWeekdays.includes(d),
      )
    ) {
      return false;
    }
    return daysTimes.every(
      timeItem =>
        timeItem.selectedDays.length &&
        timeItem.timeRanges.length &&
        timeItem.timeRanges.every(timeRange => timeRange.start_time),
    );
  }, [dateRanges, dayType, daysTimes, selectedWeekdays]);

  const timeValidatePayload = {
    type: 'Active',
    day_type: dayType,
    dates: dateRanges.map(o => ({
      start_date: o.start_date?.format('YYYY-MM-DD'),
      end_date: o.end_date?.format('YYYY-MM-DD'),
    })),
    day_times: daysTimes.map(o => ({
      days: o.selectedDays.map(d => d.toLowerCase()),
      times: o.timeRanges.map(t => t.start_time?.format('HH:mm:ss')),
    })),
  };
  if (restoreId) {
    Object.assign(timeValidatePayload, {restore_id: restoreId});
  }

  const {data, isFetching, isError} = useRqTimedMsgDateTimeExistence(
    companyId,
    timeValidatePayload,
    enableValidateApi,
  );

  const steps = useMemo(() => {
    const stepList = [
      {
        label: 'Select Stations & Message Type',
        disabled: false,
      },
      {
        label: 'Message Details',
        disabled: !isStationSelected || !isMessageNameGiven,
      },
    ];
    const restoreList = [
      {
        label: 'Select Date',
        disabled: restoreId ? false : !isDaySelectionEnabled(),
      },
      {
        label: 'Select Time',
        disabled: !isTimeSelectionEnabled,
      },
    ];
    const reviewStep = [
      {
        label: 'Review',
        disabled: !isReviewEnabled() || !!duplicateTimes.length || isFetching,
      },
    ];
    if (restoreId) {
      return [...restoreList, ...reviewStep];
    }
    return [...stepList, ...restoreList, ...reviewStep];
  }, [
    isStationSelected,
    isMessageNameGiven,
    restoreId,
    isDaySelectionEnabled,
    isTimeSelectionEnabled,
    isReviewEnabled,
    duplicateTimes.length,
    isFetching,
  ]);

  const isAddSlot = useMemo(
    () => daysTimes.length < 7 && selectedWeekdays.length < 7,
    [daysTimes.length, selectedWeekdays.length],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageName(event.target.value);
  };

  useEffect(() => {
    if (data && !isFetching && !isError) {
      setDuplicateTimes(
        data.map(o => {
          return {
            days: JSON.parse(o.week_days),
            times: JSON.parse(o.start_times),
          };
        }),
      );
    } else {
      setDuplicateTimes([]);
    }
  }, [data, isError, isFetching]); // On change date and time API call to check unique run & validate

  useEffect(() => {
    const flattenedWeeks = daysTimes.map(o => o.selectedDays).flat();
    const uniqueWeeks = flattenedWeeks.filter(
      (item, index, array) => array.indexOf(item) === index,
    );
    setSelectedWeekdays([...uniqueWeeks]);
  }, [daysTimes]);

  useEffect(() => {
    const label = steps?.[activeStepIndex]?.label ?? '';
    if (
      isReviewEnabled() &&
      isTimeSelectionEnabled &&
      (label === 'Select Stations & Message Type' ||
        label === 'Select Date' ||
        label === 'Select Time')
    ) {
      setEnableValidateApi(true);
    } else {
      setEnableValidateApi(false);
    }
  }, [activeStepIndex, isReviewEnabled, isTimeSelectionEnabled, steps]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={onClose}
      onSubmit={restoreId ? handleRestore : handleSubmit}
      onStepClick={onStepClick}
      title={
        restoreId
          ? 'Restore timed message'
          : isCopyForm
          ? 'Duplicate timed message'
          : 'Create New Message'
      }
      isSubmitted={apiState.status !== ''}
      footerLabel={footerText}>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Stations & Message Type':
            return (
              <StepperCard title='Select Options'>
                <Grid container spacing={4}>
                  <Grid item xs={5}>
                    {messageName?.trim().length > 50 && (
                      <Alert severity='error' sx={{mb: 1}}>
                        Name must below 50 characters
                      </Alert>
                    )}
                    <TextField
                      className='min-h-40'
                      id='timed-message-name'
                      placeholder='Message Name'
                      variant='outlined'
                      value={messageName}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StationsDropDown
                      stations={selectedStations}
                      setStations={setSelectedStations}
                      fetchEnable={true}
                    />
                  </Grid>
                </Grid>
                <TimedMsgRequestContentTypeSwitchForm
                  type={contentsType}
                  onChange={setContentsType}
                  setMessages={setMessages}
                  isCopyForm={isCopyForm}
                />
              </StepperCard>
            );

          case 'Message Details':
            return isCopyForm ? (
              <>
                <Typography variant='body2' className='mb-4'>
                  Messages/Song Details
                </Typography>
                {messages.map(item => (
                  <TimedMessageDetails key={item.id} message={item} />
                ))}
              </>
            ) : (
              <TimedMsgRequestContents
                type={contentsType}
                messages={messages}
                setMessages={setMessages}
                setFooterText={setFooterText}
                selectedStations={selectedStations}
              />
            );

          case 'Select Date':
            return (
              <TimedMsgRequestDays
                dayType={dayType}
                onChange={handleTypeChange}
                dates={dateRanges}
                setDateRanges={setDateRanges}
                setFooterText={setFooterText}
                isToday={!isTimeSelectionEnabled}
              />
            );

          case 'Select Time':
            return (
              <TimedMsgRequestTimes
                items={daysTimes}
                enableWeekdays
                setDaysTimes={setDaysTimes}
                selectedWeekdays={selectedWeekdays}
                isAddSlot={isAddSlot}
                duplicateTimes={duplicateTimes}
                availableDays={
                  dayType !== 'no-end'
                    ? GetDaysFromMultipleDateRange(dateRanges)
                    : undefined
                }
              />
            );

          case 'Review':
            return (
              <TimedMsgRequestReview
                stations={selectedStations}
                messageName={messageName}
                messages={messages}
                dayType={dayType}
                dateRanges={dateRanges}
                daysTimes={daysTimes}
                restoreId={restoreId}
              />
            );
          default:
            return null;
        }
      })()}

      {activeStepIndex === 4 && (
        <Typography variant='h6' className='mt-5 italic text-white-50 text-13'>
          Please choose the time when the message needs to play
        </Typography>
      )}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={restoreId ? handleRestore : handleSubmit}
          onEdit={handleEdit}
          onClose={onClose}
        />
      )}
    </StepperLayout>
  );
};

export default TimedMessageRequest;
