import {ChangeEvent, useCallback, useState} from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import {ISponsorByToken} from '../../dto/Sponsor.dto';
import {apiPost} from '../../axiosConfig';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import DialogNotes from '../../components/dialogs/DialogNotes';
import {SponsorRequestDetailsCard} from './SponsorshipRequest';
type Props = {
  data: ISponsorByToken;
  token: string;
};
const SponsorResubmitMessage = ({data, token}: Props) => {
  const [message, SetMessage] = useState('');
  const [reassignNote, SetReassignNote] = useState('');
  const [apiState, setApiState] = useState({status: '', error: null});
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [type, setType] = useState('');
  const handleMessage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    SetMessage(event.target.value);
  }, []);
  const handleReassignMessage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      SetReassignNote(event.target.value);
    },
    [],
  );
  const handleSubmit = () => {
    setApiState({status: 'pending', error: null});
    const payload = {
      message,
    };
    if (type === 'reassign') {
      payload.message = reassignNote;
    } else {
      payload.message = message;
    }
    apiPost(`v1/sponsor/${token}/resubmit`, payload)
      .then(() => {
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  const handleClose = () => {
    setApiState({
      status: '',
      error: null,
    });
    setClose(true);
  };
  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };
  const handleCopyWriterVerificationStatus = (type: string) => {
    setType(type);
    const payload = {
      type,
      message,
    };
    if (type === 'reassign') {
      payload.message = reassignNote;
    } else {
      payload.message = message;
    }
    apiPost(`v1/sponsor/${token}/copywriter-verification`, payload)
      .then(() => {
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  if (close) {
    return (
      <Alert severity='error'>
        <AlertTitle>Request has been Resubmitted sucessfully!!</AlertTitle>
      </Alert>
    );
  }
  return (
    <Stack className='flex items-center justify-center' sx={{height: '100vh'}}>
      <Box className='sponsor-info-sec'>
        <Typography variant='h4' className='text-center font-bold mb-5'>
          Sponsor Commercial
        </Typography>
        {token ? <SponsorRequestDetailsCard token={token} /> : null}
        <Stack direction={'row'} className='mb-2'>
          <Typography className='w-248 text-bold'>Company Name</Typography>
          <Typography>: {data?.company.name}</Typography>
        </Stack>
        <Stack direction={'row'} className='mb-2'>
          <Typography className='w-248 text-bold'>Submitted At</Typography>
          <Typography>
            : {dayjs(data?.created_at).format('MM/DD/YYYY hh:mm:ss A')}
          </Typography>
        </Stack>
        <Stack direction={'row'} className='mb-2'>
          <Typography className='w-248 text-bold'>Last Updated</Typography>
          <Typography>
            : {dayjs(data?.updated_at).format('MM/DD/YYYY hh:mm:ss A')}
          </Typography>
        </Stack>

        {data.type !== 'verify-copywrite-content' && (
          <>
            <Typography variant='h6' className='mt-4'>
              Task Description
            </Typography>
            <Typography>{data.sponsor_message.rejected_note}</Typography>
          </>
        )}
        <Typography variant='h6' className='mt-4'>
          Message
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={
            data?.type !== 'verify-copywrite-content'
              ? message || data.sponsor_message.message
              : data.sponsor_message.message
          }
          id='message-text-field'
          onChange={handleMessage}
          disabled={data.type === 'verify-copywrite-content'}
          defaultValue={data.sponsor_message.message}
        />
        {data.type === 'verify-copywrite-content' && (
          <>
            <Typography variant='h6' className='mt-4'>
              Copy Writer Content
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              id='message-text-field'
              value={message || data.sponsor_message.copy_writer_content}
              onChange={handleMessage}
              disabled={data.type === 'verify-copywrite-content'}
              defaultValue={data.sponsor_message.copy_writer_content}
            />
          </>
        )}
        <Stack
          direction='row'
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 5,
          }}>
          {data.type === 'verify-copywrite-content' && (
            <>
              <Button
                variant='outlined'
                onClick={() => {
                  handleCopyWriterVerificationStatus('approved');
                }}>
                Approve Copy writer content
              </Button>
              <Button variant='outlined' onClick={() => setOpen(true)}>
                Reassign to copy writer
              </Button>
            </>
          )}
          {data.type !== 'verify-copywrite-content' && (
            <Button
              variant='outlined'
              sx={{
                width: '20%',
                p: 1,
                mt: 2,
              }}
              disabled={message === ''}
              onClick={handleSubmit}>
              Resubmit
            </Button>
          )}
        </Stack>
        {apiState.status !== '' && (
          <DialogApiRequestStatus
            apiState={apiState}
            onRetry={
              data.type !== 'verify-copywrite-content'
                ? handleSubmit
                : () => handleCopyWriterVerificationStatus(type)
            }
            onEdit={handleEdit}
            onClose={handleClose}
          />
        )}
        <DialogNotes
          open={open}
          note={reassignNote}
          handleChange={handleReassignMessage}
          handleSubmit={handleCopyWriterVerificationStatus}
          handleClose={handleCancel}
        />
      </Box>
    </Stack>
  );
};
export default SponsorResubmitMessage;
