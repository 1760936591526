import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {Grid, Stack, Typography} from '@mui/material';
import TimedMsgRequestContentMessageCard, {
  iTimedMessageMessageItem,
} from './TimedMsgRequestContentMessageCard';
import TimedMsgRequestContentSongCard from './TimedMsgRequestContentSongCard';
import TimedMsgRequestSequenceBuilder from './TimedMsgRequestSequenceBuilder';
import {IStation} from '../../../../../dto/Station.dto';

type Props = {
  type: string;
  messages: iTimedMessageMessageItem[];
  setMessages: Dispatch<SetStateAction<iTimedMessageMessageItem[]>>;
  setFooterText: Dispatch<SetStateAction<string>>;
  selectedStations: IStation[];
};
const TimedMsgRequestContents = ({
  type,
  messages,
  setMessages,
  setFooterText,
  selectedStations,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleChange = (newMessage: iTimedMessageMessageItem) => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const index = newMessages.findIndex(o => o.id === newMessage.id);
      if (index !== -1) {
        newMessages.splice(index, 1, newMessage);
      }
      return newMessages;
    });
  };

  const content = useMemo(() => {
    if (type === 'sequence') {
      return messages.filter(o => o.id === selectedItem);
    }
    return messages;
  }, [messages, selectedItem, type]);

  useEffect(() => {
    if (type == 'sequence') {
      setFooterText(`Up to 20 messages/songs can be added for each request`);
    }
    return () => {
      setFooterText('');
    };
  }, [setFooterText, type]);

  return (
    <Grid container>
      {type === 'sequence' && (
        <>
          <Grid item xs={12}>
            <Stack direction='row' className='items-center'>
              <Typography variant='h5' className='mr-7'>
                Timed Sequence
              </Typography>
              <Typography variant='subtitle1' className='text-white-50 text-14'>
                A sequence couldbe a mix of Timed Messages and Songs
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={12}>
            <TimedMsgRequestSequenceBuilder
              items={messages}
              onChange={setMessages}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              type={type}
              handleChange={handleChange}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {(type === 'message' || type === 'song') && (
          <>
            {content.map(message => {
              if (message.type === 'message') {
                return (
                  <TimedMsgRequestContentMessageCard
                    key={message.id}
                    message={message}
                    setMessages={setMessages}
                    onChange={handleChange}
                  />
                );
              }
              return (
                <TimedMsgRequestContentSongCard
                  key={message.id}
                  message={message}
                  onChange={handleChange}
                  selectedStations={selectedStations}
                />
              );
            })}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TimedMsgRequestContents;
