import {lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import {CanRouteAny} from '../../components/UserCan';
import Header from '../../components/header/Header';
import {Stack} from '@mui/material';
import IconSponsor from '../../asset/icons/icon_sponsors.svg';

const SponsorsList = lazy(() => import('../../pages/sponsor/SponsorsList'));

const SponsorLayout = () => {
  return (
    <Stack direction='column' className='px-11'>
      <Header IconMessage={IconSponsor} Title='Sponsor Commercials' />
      <SponsorRoutes />
    </Stack>
  );
};

const SponsorRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <CanRouteAny scope='is-admin,partner,sponsor:list'>
            <SponsorsList />
          </CanRouteAny>
        }
      />
    </Routes>
  );
};

export default SponsorLayout;
