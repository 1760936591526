import {useCallback, useMemo} from 'react';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import {iTimedMsgTimesItem} from './TimedMsgRequestTimesCard';
import {daysOfWeek} from '../../../../../dto/DateTime.dto';

type Props = {
  itemId: number;
  selected: string[];
  selectedWeekdays: string[];
  setDaysTimes: React.Dispatch<React.SetStateAction<iTimedMsgTimesItem[]>>;
  availableDays?: string[];
};

const TimedMsgRequestWeekDayForm = ({
  itemId,
  selected,
  selectedWeekdays,
  setDaysTimes,
  availableDays,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Extract the name and checked status from the event target
    const {name, checked} = event.target;

    setDaysTimes(prevItems => {
      const index = prevItems.findIndex(o => o.id === itemId);
      if (index === -1) return prevItems;

      const newItems = [...prevItems];
      const selectedItem = newItems[index];

      if (!selectedItem) return prevItems; // Check if selectedItem is undefined

      const selectedDays = checked
        ? [...selectedItem.selectedDays, name] // Add the day if checked
        : selectedItem.selectedDays.filter(day => day !== name); // Remove the day if unchecked

      const newItem: iTimedMsgTimesItem = {
        ...selectedItem,
        selectedDays,
      };
      newItems.splice(index, 1, newItem);
      return newItems;
    });
  };

  const daysInRange = useMemo(() => {
    if (availableDays) {
      return daysOfWeek.filter(day => availableDays.includes(day));
    }
    return daysOfWeek;
  }, [availableDays]);

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {checked} = event.target;

      setDaysTimes(prevItems => {
        const index = prevItems.findIndex(o => o.id === itemId);
        if (index === -1) return prevItems;

        const newItems = [...prevItems];
        const selectedItem = newItems[index];

        if (!selectedItem) return prevItems; // Check if selectedItem is undefined

        const availableDays = daysInRange.filter(
          day => selected.includes(day) || !selectedWeekdays.includes(day),
        ) as string[];

        const newItem: iTimedMsgTimesItem = {
          ...selectedItem,
          selectedDays: checked ? availableDays : [],
        };
        newItems.splice(index, 1, newItem);
        return newItems;
      });
    },
    [daysInRange, itemId, selected, selectedWeekdays, setDaysTimes],
  );

  return (
    <>
      <FormGroup row className='py-3 border-bottom-1'>
        <FormControlLabel
          className='mr-4'
          control={
            <Checkbox
              size='small'
              name='Select All'
              checked={selectedWeekdays.length === daysInRange.length}
              onChange={handleSelectAll}
            />
          }
          label='Select All'
        />
        {daysInRange.map(day => (
          <FormControlLabel
            key={day}
            className='mr-4'
            control={
              <Checkbox
                size='small'
                name={day}
                checked={selected.includes(day)}
                onChange={handleChange}
                disabled={
                  !selected.includes(day) && selectedWeekdays.includes(day)
                }
              />
            }
            label={day}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default TimedMsgRequestWeekDayForm;
