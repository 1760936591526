import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import TnnLogo from '../../asset/images/tnn_logo.png';
import Header from '../../components/header/Header';
import IconUnchecked from '../../asset/icons/Icon_unchecked_box.svg';
import IconChecked from '../../asset/icons/icon_checked_box.svg';
import ParticipationFormStepper from './ParticipationFormStepper';
import {
  companyIdSelector,
  productSelector,
} from '../../store/Slices/companySlice';
import {apiPost} from '../../axiosConfig';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import {
  IParticipationForms,
  useRqParticipationFormId,
  useRqSponsorOptions,
} from '../../react_query/tnn-participation-form/ParticipationForm';
import IconPlay from '../../asset/icons/icon_play.svg';

const ParticipationFormOption = () => {
  const productData = useSelector(productSelector);
  const navigate = useNavigate();
  const [checkedId, setCheckedId] = useState(0);
  const videoUrl =
    productData.cmc_product === 'GameTime'
      ? 'https://vimeo.com/891139878?share=copy'
      : 'https://vimeo.com/931188273?share=copy';
  const [apiState, setApiState] = useState({
    status: '',
    error: null,
    message: '',
  });
  const [openBaseModal, setOpenBaseModal] = useState<boolean>(false);
  const companyId = useSelector(companyIdSelector);
  const {data, isFetching, isError} = useRqParticipationFormId(
    companyId,
    Boolean(companyId),
  );
  const participationFormDetails = useMemo(() => {
    if (data && !isFetching && !isError) {
      setCheckedId(data.sponsor_option_id);
      return data;
    }
    return {} as IParticipationForms;
  }, [data, isFetching, isError]);

  const closeBaseModal = () => {
    setOpenBaseModal(false);
  };

  const handleClick = useCallback(() => {
    if ([3].includes(checkedId)) {
      const submitUrl =
        participationFormDetails && participationFormDetails.id
          ? `v1/company/${companyId}/participation-form/${participationFormDetails.id}?_method=PUT`
          : `v1/company/${companyId}/participation-form`;
      setApiState({status: 'pending', error: null, message: ''});
      const fd = new FormData();
      fd.append('sponsor_option_id', checkedId.toString());

      apiPost(submitUrl, fd)
        .then(() => {
          setApiState({
            status: 'success',
            error: null,
            message: 'Thank you for considering TNN',
          });
        })
        .catch(error => {
          setApiState({status: 'error', error, message: ''});
        });
    } else {
      setOpenBaseModal(true);
    }
  }, [checkedId, companyId, participationFormDetails]);

  const handleEdit = useCallback(() => {
    setApiState({status: '', error: null, message: ''});
  }, []);

  return (
    <>
      <Stack direction='column' className='px-11'>
        <Header IconMessage={TnnLogo} imgClassName='w-130 h-90 mr-4' Title='' />
        <Grid
          container
          className='flex justify-center items-center px-5 min-h-65 blue-variant17 radius-10-0'>
          <Typography
            variant='h5'
            className='px-12 font-bolder m-0 text-white-50'>
            <b>Participate in The Neptune Network</b>
          </Typography>
        </Grid>
        <Grid container height={'738px'}>
          <Grid
            item
            xs={7}
            className='bg-blue-variant-47-rt-gradiant px-25 py-15'>
            <Typography variant='h5' className='font-bold mb-4'>
              <b>{`${productData.cmc_product} Partners`}</b>
            </Typography>
            <Typography className='mb-4'>
              Welcome to <b>The Neptune Network (TNN)</b> section of{' '}
              <b>{`${productData.cmc_product}!`}</b>
            </Typography>
            <Typography className='mb-4'>
              Hopefully, by now, your Product Representative or Katie Spitzer
              from our Revenue Development Team has told you all about{' '}
              <b>TNN</b>.
            </Typography>
            <Typography className='mb-4'>
              As a reminder, <b>The Neptune Network</b> is where WE sell
              advertising sponsorships for your{' '}
              <b>{`${productData.cmc_product}`}</b> <br /> station on your
              behalf and then split the revenue with you. For more details on
              how this works, you <br /> can check out this overview VIDEO.
            </Typography>
            <VideoPlayButton videoUrl={videoUrl} />
            <Typography className='my-4'>
              Participation in <b>TNN</b> is entirely optional. If you need
              additional information before you decide whether or not to
              participate, please contact Gerald Gray at{' '}
              <Link href='mailto:gerald@neptunenow.com'>
                gerald@neptunenow.com
              </Link>
              .
            </Typography>
            <Typography>
              Otherwise, please indicate your preference in the panel on the
              right.
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            className='relative blue-variant29 p-15 h-full'
            sx={{
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: '15px',
                right: '25px',
                width: '315px',
                height: '220px',
                backgroundImage: `url(${TnnLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                opacity: '0.1',
              },
            }}>
            <Typography variant='h5' className='font-bold mb-4'>
              Select Participation Option
            </Typography>
            <Typography className='text-white-50 mb-4 text-16 italic'>
              You can always change this by returning to this section and
              updating your selection. However, if you opt into TNN and we sell
              ads on your behalf, you CANNOT opt out unless you give us 90 days’
              notice.
            </Typography>
            <ParticipationFormOptionCheckBoxCmpt
              checkedId={checkedId}
              setCheckedId={setCheckedId}
            />
            <Button
              variant='contained'
              onClick={handleClick}
              disabled={!Boolean(checkedId)}>
              Proceed
            </Button>
          </Grid>
        </Grid>
      </Stack>

      {openBaseModal && (
        <ParticipationFormStepper
          handleCancel={closeBaseModal}
          checkedId={checkedId}
          participationFormDetails={participationFormDetails}
          productType={productData.cmc_product}
        />
      )}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleClick}
          onEdit={handleEdit}
          onClose={() => navigate('/dashboard')}
        />
      )}
    </>
  );
};

export default ParticipationFormOption;

const ParticipationFormOptionCheckBoxCmpt = ({
  checkedId,
  setCheckedId,
}: {
  checkedId: number;
  setCheckedId: (val: number) => void;
}) => {
  const handleChecked = (id: number) => {
    setCheckedId(id);
  };
  const {data, isFetching, isError} = useRqSponsorOptions();

  return (
    <FormGroup className='my-4'>
      {data &&
        !isFetching &&
        !isError &&
        data.map(val => (
          <FormControlLabel
            key={val.id}
            className='my-2'
            control={
              <Checkbox
                tabIndex={-1}
                onChange={() => handleChecked(val.id)}
                value={val.id}
                checked={val.id === checkedId}
                icon={<img src={IconUnchecked} alt='unchecked' />}
                checkedIcon={<img src={IconChecked} alt='checked' />}
              />
            }
            label={val.option}
          />
        ))}
    </FormGroup>
  );
};

const VideoPlayButton = ({videoUrl}: {videoUrl: string}) => {
  return (
    <Button
      component='a'
      variant='outlined'
      color='info'
      href={videoUrl}
      size='small'
      target='_blank'
      rel='noopener noreferrer'>
      <img src={IconPlay} className='mr-2 w-20' />
      <Typography className='text-14 text-white ml-1'>Play Video</Typography>
    </Button>
  );
};
