import {Dispatch, SetStateAction, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Edit} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {IScripts} from './BaseMessagesReducer';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import IconDeleteButton from '../../../../components/Button/IconDeletebutton';
import BaseMessageForm from './BaseMessageForm';
import {manipulateText} from '../../../../components/utlis/date-time/SecondsNumberToTimeFormat';
import {IEventType} from '../../../../dto/EventType.dto';
import {SeasonEventTableCell} from './BaseMessageReview';
import {isGTSelector} from '../../../../store/Slices/companySlice';

type Props = {
  messages: IScripts[];
  setMessages: Dispatch<SetStateAction<IScripts[]>>;
  isNewBaseMessage: boolean;
  setSelectedTracks: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
  othersEventName: string;
  setOthersEventName: React.Dispatch<React.SetStateAction<string>>;
  events: IEventType[];
  setEvents: React.Dispatch<React.SetStateAction<IEventType[]>>;
};

const BaseMessageInput = ({
  messages,
  setMessages,
  isNewBaseMessage,
  setSelectedTracks,
  setOthersEventName,
  othersEventName,
  events,
  setEvents,
}: Props) => {
  const isStaff = useSelector(isInternalSelector);
  const isGT = useSelector(isGTSelector);

  const deleteMultiSelectData = (msg: IScripts) => {
    setMessages(prev => prev.filter(v => v.uuid !== msg.uuid));
    if (msg.media_track) {
      setSelectedTracks(prev => {
        const removedSongs = prev.filter(val => val.id !== msg.media_track?.id);
        return removedSongs;
      });
    }
  };

  const handleEdit = useCallback(
    (uuid: string) => {
      setMessages(prev => {
        const messages = [...prev.filter(o => o.name || o.message)];
        const index = messages.findIndex(message => message.uuid === uuid);
        if (index !== -1) {
          const splicedMessage = messages.splice(index, 1)[0] as IScripts; // Remove the message from its current position
          setEvents(splicedMessage?.events);
          messages.unshift(splicedMessage); // Move the message to the first position
        }
        return [...messages];
      });
    },
    [setMessages, setEvents],
  );

  return (
    <>
      <BaseMessageForm
        key={messages[0]?.uuid}
        message={messages[0] as IScripts}
        setMessages={setMessages}
        isNewBaseMessage={isNewBaseMessage}
        isEditForm={false}
        setOthersEventName={setOthersEventName}
        othersEventName={othersEventName}
        setEvents={setEvents}
        events={events}
      />
      {messages && messages.length > 0 && (
        <Box className='max-h-250 scrollbar overflow-auto'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isStaff ? <TableCell>Play</TableCell> : null}
                  <TableCell align='left' width={297}>
                    <Typography className='text-16 opacity-50 text-white'>
                      Message Name
                    </Typography>
                  </TableCell>
                  <TableCell align='left'>
                    <Typography className='text-16 opacity-50 text-white'>
                      Message
                    </Typography>
                  </TableCell>
                  <TableCell align='left'>
                    <Typography className='text-16 opacity-50 text-white'>
                      {isGT ? 'Sports/Events' : 'Seasons'}
                    </Typography>
                  </TableCell>
                  <TableCell width={72}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((item, index) => {
                  if (index) {
                    return (
                      <TableRow key={item.uuid}>
                        {isStaff ? (
                          <TableCell width={80} align='left'>
                            {item.media_track ? (
                              <MusicPreviewBtn
                                id={item.media_track.id}
                                url={item.media_track.url}
                              />
                            ) : null}
                          </TableCell>
                        ) : null}
                        <TableCell align='left'>
                          <Typography
                            variant='caption'
                            className='text-16 text-white'
                            title={item.name}>
                            {manipulateText(item.name ?? '', 15)}
                          </Typography>
                        </TableCell>
                        <TableCell align='left'>
                          <Typography
                            variant='caption'
                            className='text-16 text-white'
                            title={item.message}>
                            {manipulateText(item.message ?? '', 50)}
                          </Typography>
                        </TableCell>
                        <TableCell align='left'>
                          <Typography
                            variant='caption'
                            className='text-16 text-white'>
                            <SeasonEventTableCell val={item} />
                          </Typography>
                        </TableCell>
                        <TableCell align='center'>
                          <Stack direction='row' spacing={1}>
                            <IconButton
                              aria-label='edit'
                              onClick={() => handleEdit(item.uuid)}>
                              <Edit />
                            </IconButton>
                            <IconDeleteButton
                              onClick={() => {
                                deleteMultiSelectData(item);
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
export default BaseMessageInput;
