import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {IOnDemCustLib} from './OndemandStepper';
import {DialogClose} from '../../../components/dialogs/DialogFrame/DialogClose';
import CommonCusDropDown from './CommonCusDropDown';

const OndemandCustomLibrary = ({
  libraries,
  setLibraries,
  selected,
  setSelected,
}: {
  libraries: IOnDemCustLib[];
  setLibraries: React.Dispatch<React.SetStateAction<IOnDemCustLib[]>>;
  selected: IOnDemCustLib[];
  setSelected: React.Dispatch<React.SetStateAction<IOnDemCustLib[]>>;
}) => {
  const [open, setOpen] = useState(false);
  const [newPlaylist, setNewPlaylist] = useState('');

  const handleClose = useCallback(() => {
    setSelected(prev => prev.filter(o => o.id !== 0));
    setOpen(false);
  }, [setSelected]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPlaylist(event.target.value);
  };

  const handleAddPlaylist = useCallback(() => {
    if (newPlaylist !== '') {
      const newLibaray = {
        id: Date.now(),
        name: newPlaylist,
        station_name: '',
        new: true,
      };
      setSelected(prev => [...prev, newLibaray]);
      setLibraries(prev => [...prev, newLibaray]);
      setNewPlaylist('');
      handleClose();
    }
  }, [handleClose, newPlaylist, setLibraries, setSelected]);

  useEffect(() => {
    if (selected.some(o => o.id === 0)) {
      setOpen(true);
    }
  }, [selected]);

  return (
    <>
      <CustomLibrary
        libraries={libraries}
        selected={selected}
        setSelected={setSelected}
      />

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        onClose={handleClose}
        aria-labelledby='dialog-title'
        aria-describedby='dialog-description'>
        <DialogTitle>
          <Typography>Add New Custom Library</Typography>
          <DialogClose onClose={handleClose} />
        </DialogTitle>
        <DialogContent className='text-center'>
          <TextField
            id='new-library'
            value={newPlaylist}
            onChange={handleChange}
            className='max-w-500 w-full mt-4 mb-3'
          />
        </DialogContent>
        <DialogActions
          className='flex justify-center mb-3'
          sx={{justifyContent: 'center'}}>
          <Button
            onClick={handleAddPlaylist}
            disabled={!newPlaylist}
            variant='contained'>
            Add
          </Button>
          <Button onClick={handleClose} variant='contained'>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OndemandCustomLibrary;

export const CustomLibrary = ({
  libraries,
  selected,
  setSelected,
}: {
  libraries: IOnDemCustLib[];
  selected: IOnDemCustLib[];
  setSelected: React.Dispatch<React.SetStateAction<IOnDemCustLib[]>>;
}) => {
  const handleCancelLibrary = (libraryId: number) => {
    setSelected(prevLibraries =>
      prevLibraries.filter(library => library.id !== libraryId),
    );
  };

  const deselectAllLibraries = () => {
    setSelected([]);
  };
  return (
    <>
      <Autocomplete
        multiple
        fullWidth
        disableCloseOnSelect
        sx={{
          width: 300,
          '.MuiInputBase-root .MuiButtonBase-root': {display: 'none'},
        }}
        id='custom-library'
        onChange={(_, values) => {
          setSelected(values as IOnDemCustLib[]);
        }}
        value={selected}
        options={libraries}
        getOptionLabel={option =>
          `${option.name} ${option.station_name ? '-' : ''} ${
            option.station_name
          }`
        }
        className='h-55 w-300 m-0 mr-10 overflow-hidden custDpIcon'
        renderInput={params => (
          <TextField {...params} placeholder='Select Custom Libraries' />
        )}
        renderOption={(props, option, {selected}) => (
          <li
            {...props}
            className='autoselect-option options-select flex items-center pl-6 py-2 cursor-pointer'>
            <Typography
              component='h6'
              className='flex items-center
           '>
              <Checkbox
                checked={selected}
                sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
              />
              {option.name}
            </Typography>
          </li>
        )}
        ListboxProps={{
          style: {
            maxHeight: '300px',
          },
        }}
      />
      {selected.length > 0 && (
        <CommonCusDropDown
          showData={selected}
          cancelData={handleCancelLibrary}
          deselectAll={deselectAllLibraries}
        />
      )}
    </>
  );
};
