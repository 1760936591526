import {Box, Card, Container} from '@mui/material';
import {ReactNode} from 'react';
import {useStylesed} from './commonStyled';

interface CardProps {
  children: ReactNode;
}

const FlexCardContent: React.FC<CardProps> = ({children}) => {
  const classes = useStylesed();
  return (
    <Container maxWidth='xl' className='h-full'>
      <Card
        elevation={0}
        className={`${classes.modelCard}, h-full flex flex-column overflow-auto`}>
        {children}
      </Card>
    </Container>
  );
};

export default FlexCardContent;

export const FlexDivLayout: React.FC<CardProps> = ({children}) => {
  const classes = useStylesed();
  return (
    <Box className={classes.c_flex} sx={{position: 'relative'}}>
      {children}
    </Box>
  );
};
