import {AxiosResponse} from 'axios';
import {useQuery} from '@tanstack/react-query';
import axiosInstance from '../../../axiosConfig';
import {IApiPaginationResponse} from '../../../dto/API.dto';
import {IDataGridSearchSort} from '../../../dto/Html.dto';
import {IMessage} from '../../../dto/Message.dto';

export const useRqStingerMessageList = (
  company_id: number,
  parms: IDataGridSearchSort,
) => {
  const params = {...parms, page: parms.page + 1};
  return useQuery({
    queryKey: [`v1/company/${company_id}/message/stinger-message`, params],
    queryFn: () => getStingerMgsList(company_id, params),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const getStingerMgsList = (company_id: number, params: IDataGridSearchSort) => {
  return axiosInstance
    .get(`v1/company/${company_id}/message/stinger-message`, {
      params,
    })
    .then((response: AxiosResponse<IApiPaginationResponse<IMessage[]>>) => {
      return response.data;
    });
};
