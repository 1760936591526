import {Box, Stack, Typography} from '@mui/material';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import TimedMsgRequestContentMessageForm from './TimedMsgRequestContentMessageForm';
import {IMediaTrack} from '../../../../../dto/MediaTrack.dto';
import {isInternalSelector} from '../../../../../store/Slices/userSlice';
import MusicPreviewBtn from '../../../../../components/music/MusicPreviewBtn';
import ExistingCampaignChooserBtn from '../../../../sponsor/ExistingCampaignChooserBtn';
import {useStoreSelector} from '../../../../../store/configstore';
import {companyIdSelector} from '../../../../../store/Slices/companySlice';

export type iTimedMessageMessageItem = {
  id: number;
  type: 'message' | 'song';
  message: string;
  track?: IMediaTrack;
  artist: string;
  artistErrorMsg?: string; // used for new song request
  title: string; // used for new song request
  titleErrorMsg?: string; // used for new song request
  file?: File; // used for new file attachment
  label?: string;
};

type Props = {
  message: iTimedMessageMessageItem;
  onChange: (newMessage: iTimedMessageMessageItem) => void;
  setMessages: Dispatch<SetStateAction<iTimedMessageMessageItem[]>>;
  type?: string;
  label?: string;
};

const TimedMsgRequestContentMessageCard = ({
  message,
  setMessages,
  onChange,
  type,
  label,
}: Props) => {
  const handleMessageChange = (text: string) => {
    onChange({...message, message: text});
  };
  const [selectedTracks, setSelectedTracks] = useState<IMediaTrack[]>([]);
  const isStaff = useSelector(isInternalSelector);
  const companyId = useStoreSelector(companyIdSelector);
  const messageId = message.id;

  useEffect(() => {
    setMessages(prev => {
      const finalData = prev.map(val => {
        if (selectedTracks.length !== 0 && val.id === messageId) {
          return {...val, track: selectedTracks[0]};
        }
        return val;
      });
      return finalData;
    });
  }, [messageId, selectedTracks, setMessages]);

  return (
    <>
      <Box className='flex justify-between items'>
        {type !== 'sequence' && (
          <Typography variant='h5' className='font-bold text-white mb-4'>
            Timed Message
          </Typography>
        )}
        {isStaff ? (
          <Box textAlign='end'>
            <ExistingCampaignChooserBtn
              tracks={selectedTracks}
              onChange={setSelectedTracks}
              type='timed-message'
              label='Choose Timed Message'
              max={1}
              companyId={companyId}
            />
          </Box>
        ) : null}
      </Box>
      <TimedMsgRequestContentMessageForm
        value={message.message}
        id={message.id}
        onChange={handleMessageChange}
        label={label}
      />
      {message.track ? (
        <Stack
          className='p-2 radius-5 mt-2 flex-row items-center justify-between max-w-550 w-full'
          sx={{border: '1px solid var(--gray-opacity-variant2)'}}>
          <Typography>{message.track?.track_name}</Typography>
          <MusicPreviewBtn
            id={message.track.id}
            url={message.track.url}
            key={message.track.url}
          />
        </Stack>
      ) : null}
    </>
  );
};

export default TimedMsgRequestContentMessageCard;
