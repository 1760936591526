import {Alert, AlertTitle, Button, Container} from '@mui/material';
import {Link} from 'react-router-dom';
import {useUserCan, useUserCanAny} from '../hooks/useUserCan';

type Props = {
  scope: string;
  children: JSX.Element;
};

export default function UserCan({scope, children}: Props) {
  const havePermission = useUserCan(scope);
  if (havePermission) return children;
  return null;
}

export function UserCanAny({scope, children}: Props) {
  const havePermission = useUserCanAny(scope);
  if (havePermission) return children;
  return null;
}

export const CanRouteAny = ({scope, children}: Props) => {
  const havePermission = useUserCanAny(scope);
  if (havePermission) return children;

  return (
    <Container>
      <Alert
        variant='outlined'
        severity='error'
        sx={{my: 2}}
        action={
          <Button variant='contained' color='info' component={Link} to='/'>
            GO TO HOMEPAGE
          </Button>
        }>
        <AlertTitle>ACCESS DENIED</AlertTitle>
        Sorry, you do not have permission to access this page.
        <p>You need to have at least one of the following permissions: </p>
        <ul>
          {scope.split(',').map(permission => (
            <li key={permission}>{permission.trim()}</li>
          ))}
        </ul>
      </Alert>
    </Container>
  );
};
