import {
  FormControl,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {
  DatePicker,
  DateRange,
  DateRangePicker,
  TimePicker,
} from '@mui/x-date-pickers-pro';
import {useCallback, useEffect, useState} from 'react';
import {IStartEndDateTime} from '../../../dto/DateTime.dto';
import {IMsgReqEventDateType} from '../../../dto/MessageRequest.dto';
import CustomCalIcon from '../../../asset/images/calendar.png';
import IconDeleteButton from '../../../components/Button/IconDeletebutton';
import './datePicker.css';
import {
  GetDaysFromDateRange,
  GetDaysFromMultipleDateRange,
} from '../../../components/utlis/date-time/GetDaysInDateRange';

interface multiDateInterface {
  iswholeDay: boolean;
  eventDateType: IMsgReqEventDateType;
  setMultipleDates: React.Dispatch<React.SetStateAction<IStartEndDateTime[]>>;
  invalidDate: boolean;
  multiple_dates: IStartEndDateTime[];
  setDays: React.Dispatch<React.SetStateAction<string[]>>;
  days: string[];
  savedOldDays: string[];
}

export const newStartEndDateTime = {
  id: Date.now(),
  start_date: null,
  end_date: null,
  start_time: dayjs().startOf('day'),
  end_time: dayjs().endOf('day'),
  is_whole_day: true,
};

export const MultiSelectDateTimeView = ({
  iswholeDay,
  multiple_dates,
  setMultipleDates,
  eventDateType,
  invalidDate,
  setDays,
  days,
  savedOldDays,
}: multiDateInterface) => {
  const [dateRangePickerValue, setDateRangePickerValue] = useState<
    DateRange<Dayjs> | [null, null]
  >([null, null]);
  const [startTime, setStartTime] = useState<Dayjs | null>(
    dayjs().startOf('d'),
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(dayjs().endOf('d'));
  useEffect(() => {
    setDateRangePickerValue([
      multiple_dates[0]?.start_date
        ? dayjs(multiple_dates[0].start_date)
        : null,
      multiple_dates[0]?.end_date ? dayjs(multiple_dates[0].end_date) : null,
    ]);
  }, [eventDateType, multiple_dates]);

  const handleSingleDateChange = (date: Dayjs | null) => {
    setDateRangePickerValue([null, null]);
    setMultipleDates(prev => {
      if (!prev.length) {
        return [
          {
            id: Date.now(),
            start_date: date,
            end_date: date,
            start_time: startTime,
            end_time: endTime,
            is_whole_day: iswholeDay,
          },
        ];
      }
      const dates = {...prev[0]} as IStartEndDateTime;
      return [{...dates, start_date: date, end_date: date}];
    });
    if (date) {
      setDays([date.format('dddd')]);
    }
  };

  const handleDateRangeChange = (newValue: DateRange<Dayjs>) => {
    setDateRangePickerValue(newValue);
    const dateRange = {
      id: Date.now(),
      start_date: newValue[0] ?? null,
      end_date: newValue[1] ?? null,
      start_time: startTime,
      end_time: endTime,
      is_whole_day: iswholeDay,
    };
    setMultipleDates([dateRange]);
    if (newValue[0] && newValue[1]) {
      const newDays = GetDaysFromDateRange(
        newValue[0],
        newValue[1],
      ) as string[];
      setDays(newDays.filter(d => savedOldDays.includes(d)));
    }
  };

  const handleStartTime = (time: dayjs.Dayjs | null) => {
    setStartTime(time);
    if (eventDateType === 1) {
      setMultipleDates(prev => {
        if (!prev.length) {
          return [
            {
              id: Date.now(),
              start_date: null,
              end_date: null,
              start_time: time,
              end_time: null,
              is_whole_day: true,
            },
          ];
        }
        const dates = {...prev[0]} as IStartEndDateTime;
        return [{...dates, start_time: time}];
      });
    }
  };

  const handleEndTime = (time: dayjs.Dayjs | null) => {
    setEndTime(time);
    if (eventDateType === 1) {
      setMultipleDates(prev => {
        if (!prev.length) {
          return [
            {
              id: Date.now(),
              start_date: null,
              end_date: null,
              start_time: null,
              end_time: time,
              is_whole_day: true,
            },
          ];
        }
        const dates = {...prev[0]} as IStartEndDateTime;
        return [{...dates, end_time: time}];
      });
    }
  };

  const handleDay = useCallback(
    (day: string) => {
      setDays(prev => {
        if (prev.includes(day)) {
          return prev.filter(d => d !== day);
        }
        return [...prev, day];
      });
    },
    [setDays],
  );

  useEffect(() => {
    setMultipleDates(prev => {
      const singleDate = {...prev[0]} as IStartEndDateTime;
      return [
        {
          ...singleDate,
          start_time: startTime,
          end_time: endTime,
          is_whole_day: iswholeDay,
        },
      ];
    });
  }, [endTime, eventDateType, iswholeDay, setMultipleDates, startTime]);

  useEffect(() => {
    if (iswholeDay) {
      setStartTime(dayjs().startOf('d'));
      setEndTime(dayjs().endOf('d'));
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  }, [iswholeDay]);

  function MuiIcon() {
    return (
      <img src={CustomCalIcon} alt='Date picker opening icon' width={23} />
    );
  }
  return (
    <Grid item xs={12}>
      <Grid
        container
        alignItems='center'
        justifyContent='flex-start'
        spacing={2}>
        <Grid item xs={6} className='common-date-picker'>
          {eventDateType === 1 ? (
            <DatePicker
              label='Event Date'
              slots={{openPickerIcon: MuiIcon}}
              disablePast
              value={multiple_dates[0]?.start_date ?? null}
              sx={{width: 200}}
              onChange={handleSingleDateChange}
              className='popupDatePicker'
            />
          ) : (
            <>
              <DateRangePicker
                disablePast
                localeText={{start: 'Start Date', end: 'End Date'}}
                value={dateRangePickerValue}
                onChange={handleDateRangeChange}
                className='popupDatePicker'
              />
              <Stack mt={2} ml={2}>
                {GetDaysFromMultipleDateRange(multiple_dates).map(day => (
                  <FormControlLabel
                    key={day}
                    control={
                      <Checkbox
                        name={day}
                        checked={days.includes(day)}
                        onChange={() => handleDay(day)}
                      />
                    }
                    label={day}
                  />
                ))}
              </Stack>
            </>
          )}
        </Grid>
        <Grid item xs={5} className='common-date-picker'>
          {iswholeDay ? null : (
            <FormControl>
              <Stack direction='row' spacing={1}>
                <TimePicker
                  label='Start time'
                  value={startTime}
                  onChange={handleStartTime}
                  className='popupDatePicker'
                  sx={{
                    '& .MuiButtonBase-root': {
                      textDecoration: 'none !important',
                    },
                  }}
                />
                <TimePicker
                  label='End time'
                  value={endTime}
                  onChange={handleEndTime}
                  className='popupDatePicker'
                  sx={{
                    '& .MuiPickersToolbar-root': {
                      color: '#1565c0',
                      borderRadius: '2px',
                      borderWidth: '1px',
                      borderColor: '#2196f3',
                      borderStyle: 'solid', // Specify border style separately
                      backgroundColor: '#90caf9',
                    },
                  }}
                />
              </Stack>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          {invalidDate ? (
            <Alert severity='error' sx={{mb: 1}}>
              Date/Time intersect each other
            </Alert>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const EventMultiSelectList = ({
  listDates,
  deleteMultiSelectData,
}: {
  listDates: IStartEndDateTime[];
  deleteMultiSelectData: (row: IStartEndDateTime) => void;
}) => {
  return (
    <Grid item xs={12}>
      <TableContainer
        component={Paper}
        className='indReviewTable blue-variant17'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listDates.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.start_date?.format('MM/DD/YYYY')}</TableCell>
                <TableCell>{row.end_date?.format('MM/DD/YYYY')}</TableCell>
                <TableCell>{row.start_time?.format('hh:mm A')}</TableCell>
                <TableCell>{row.end_time?.format('hh:mm A')}</TableCell>
                <TableCell>
                  <IconDeleteButton
                    aria-label='delete'
                    onClick={() => deleteMultiSelectData(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
