import {Typography} from '@mui/material';
import {IStation} from '../../../dto/Station.dto';
import {IScripts} from '../event-specific/EventSpecificStepper';
import {
  MessageReviewLine,
  MessageReviewTable,
  MessageSingleReviewLine,
} from '../MessageReviewLine';
import {ICustomLibrary} from '../../../dto/Message.dto';
import {commonDateFormat} from '../../../Service/CommonService';

type Props = {
  stations: IStation[];
  libraries: ICustomLibrary[];
  is_expire_date_set: boolean;
  expire_date: string | null;
  messages: IScripts[];
  from?: string;
};

export const OndemandReview = ({
  stations,
  libraries,
  is_expire_date_set,
  expire_date,
  messages,
  from = '',
}: Props) => {
  return (
    <>
      {from !== 'view' && (
        <Typography variant='h5' className='mb-5'>
          Review
        </Typography>
      )}
      <MessageReviewLine
        sectionTitle='Stations'
        sectionArrayData={stations}
        aria-label='station-list'
      />
      {libraries.length > 0 && (
        <MessageReviewLine
          sectionTitle='Custom libraries'
          sectionArrayData={libraries}
          showOnlyName={true}
        />
      )}
      <MessageSingleReviewLine
        sectionTitle='End Date'
        sectionContent={
          is_expire_date_set ? commonDateFormat(expire_date) : 'NA'
        }
      />
      <MessageReviewTable sectionTitle='Message Details' messages={messages} />
    </>
  );
};
