import {ChangeEvent, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import dayjs, {Dayjs} from 'dayjs';
import {useQueryClient} from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import {companyIdSelector} from '../../../../store/Slices/companySlice';
import {apiPost} from '../../../../axiosConfig';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import {getSeasonUrl} from '../../../../react_query/seasons/ListSeasons';
import {DialogClose} from '../../../../components/dialogs/DialogFrame/DialogClose';
import IconCalendar from '../../../../asset/icons/icon_calendar_gray.svg';
type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  onClose: () => void;
};
const BaseCreateSeasonDialog = ({open, setOpen, onClose}: Props) => {
  const [name, setName] = useState('');
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const companyId = useSelector(companyIdSelector);
  const [apiState, setApiState] = useState({status: '', error: null});
  const queryClient = useQueryClient();
  const handleSubmit = () => {
    setApiState({status: '', error: null});
    const payload = {
      name,
      start_date: dateRange[0] ? dateRange[0].toISOString() : '',
      end_date: dateRange[1] ? dateRange[1].toISOString() : '',
    };
    apiPost(`v1/company/${companyId}/seasons`, payload)
      .then(() => {
        setApiState({status: 'success', error: null});
        setDateRange([null, null]);
        queryClient.invalidateQueries({
          queryKey: [getSeasonUrl(companyId)],
          refetchType: 'active',
        });
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);
  const handleClose = () => {
    setOpen(false);
    setApiState({
      status: '',
      error: null,
    });
  };
  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Typography>Add Season</Typography>
        <DialogClose onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack direction='column' spacing={2}>
          <TextField
            id='season-name-text'
            placeholder='Season Name'
            variant='outlined'
            onChange={handleChange}
          />
          <DateRangePicker
            slots={{field: SingleInputDateRangeField}}
            value={dateRange}
            onChange={setDateRange}
            minDate={dayjs().add(1, 'day')}
            slotProps={{
              textField: {
                size: 'small',
                InputProps: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <img src={IconCalendar} alt='' className='w-22' />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions className='px-8 pb-6'>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={
            name === '' || dateRange[0] === null || dateRange[1] === null
          }>
          Submit
        </Button>
      </DialogActions>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleClose}
        />
      )}
    </Dialog>
  );
};

export default BaseCreateSeasonDialog;
