import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import {useCallback, useState} from 'react';
import dayjs from 'dayjs';
import {IMsgReqEventDateType} from '../../../dto/MessageRequest.dto';
import {IStartEndDateTime} from '../../../dto/DateTime.dto';
import {MultiSelectDateTimeView} from './MultiSelectDateTimeView';
import CustomizedSwitches from '../../../components/styles/ToggleButton';

export const newStartEndDateTime = {
  id: Date.now(),
  start_date: null,
  end_date: null,
  start_time: dayjs().startOf('day'),
  end_time: dayjs().endOf('day'),
  is_whole_day: true,
};

const EsmcDatesTimes = ({
  invalidDate,
  event_date_type,
  multiple_dates,
  setEventDateType,
  setMultipleDates,
  isPastTime = false,
  isPastDate = false,
  days,
  setDays,
  savedOldDays,
}: DaySelectionProps) => {
  return (
    <Box>
      <Typography variant='h5' className='text-white font-bold mt-2 mb-6 m-0'>
        Please enter the date
      </Typography>
      <DaysSelection
        invalidDate={invalidDate}
        event_date_type={event_date_type}
        multiple_dates={multiple_dates}
        setEventDateType={setEventDateType}
        setMultipleDates={setMultipleDates}
        days={days}
        setDays={setDays}
        savedOldDays={savedOldDays}
      />

      {isPastDate && multiple_dates.find(x => x.start_date && x.end_date) && (
        <Alert severity='error' sx={{mb: 1}}>
          Choose a day other than today.
        </Alert>
      )}
      {isPastDate &&
        event_date_type === 2 &&
        multiple_dates.find(x => !x.start_date || !x.end_date) && (
          <Alert severity='error' sx={{mb: 1}}>
            Date field should not be empty.
          </Alert>
        )}
      {isPastTime && (
        <Alert severity='error' sx={{mb: 1}}>
          Please ensure that the start time is earlier than the finish time.
        </Alert>
      )}
    </Box>
  );
};

export default EsmcDatesTimes;

type DaySelectionProps = {
  invalidDate: boolean;
  event_date_type: IMsgReqEventDateType;
  multiple_dates: IStartEndDateTime[];
  setEventDateType: (val: IMsgReqEventDateType) => void;
  setMultipleDates: React.Dispatch<React.SetStateAction<IStartEndDateTime[]>>;
  days: string[];
  setDays: React.Dispatch<React.SetStateAction<string[]>>;
  isPastTime?: boolean;
  isPastDate?: boolean;
  savedOldDays: string[];
};

export const DaysSelection = ({
  invalidDate,
  event_date_type,
  multiple_dates,
  setEventDateType,
  setMultipleDates,
  days,
  setDays,
  savedOldDays,
}: DaySelectionProps) => {
  const handleEventTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEventDateType(Number(e.target.value) as IMsgReqEventDateType);
      setMultipleDates([]);
      setIsWholeDay(true);
      if (Number(e.target.value) === 2) {
        setDays(savedOldDays);
      } else {
        setDays([]);
      }
    },
    [savedOldDays, setDays, setEventDateType, setMultipleDates],
  );

  const [iswholeDay, setIsWholeDay] = useState(true);
  const handleWholeDay = useCallback(() => {
    setIsWholeDay(prev => !prev);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack direction='row' alignItems='center' className='pl-2'>
          <FormControl>
            <FormGroup>
              <RadioGroup
                row
                aria-labelledby='days_selection_label'
                name='radio-buttons-group'
                value={event_date_type}
                id='radio-btn'
                onChange={handleEventTypeChange}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label='One Day Only'
                  className='text-white'
                  defaultChecked
                />
                <FormControlLabel
                  className='pr-5 text-white'
                  value={2}
                  control={<Radio />}
                  label='Multiple Days'
                />
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={6} className='flex'>
        <Stack direction='row' alignItems='center'>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomizedSwitches
                    isActive={iswholeDay}
                    onChange={handleWholeDay}
                  />
                }
                label='Whole day'
                className='text-white'
              />
            </FormGroup>
          </FormControl>
        </Stack>
        {invalidDate ? (
          <Alert severity='error' sx={{mb: 1}}>
            Date/Time intersect each other
          </Alert>
        ) : null}
      </Grid>
      <MultiSelectDateTimeView
        iswholeDay={iswholeDay}
        multiple_dates={multiple_dates}
        setMultipleDates={setMultipleDates}
        eventDateType={event_date_type}
        invalidDate={invalidDate}
        setDays={setDays}
        days={days}
        savedOldDays={savedOldDays}
      />
    </Grid>
  );
};
