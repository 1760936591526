import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {useParams} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {CloudUpload} from '@mui/icons-material';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import {DataGridCustPagination} from '../../components/utlis/DataGrid/Pagination';
import {
  IDataGridProSearchSort,
  defaultDataGridProSearchSort,
} from '../../dto/Html.dto';
import {useStylesed} from '../../components/styles/commonStyled';
import {useStoreSelector} from '../../store/configstore';
import {
  useRqMediaTracksPaginate,
  useRqMediaTracksWithoutCompanyPaginate,
} from '../../react_query/media_tracks/ListMediaTrackPaginatPro';
import {companyIdSelector} from '../../store/Slices/companySlice';
import {useRqTaskInfo} from '../../react_query/tasks/ListTasks';
import {apiPost} from '../../axiosConfig';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import {queryKeyGenericStingerTracksRequestList} from '../admin/global-content/generic-stingers-list/GenericStingersQueries';
import {queryKeyGenericBasetList} from '../../react_query/global-content/genericBaseService';
import {IApiPaginationResponse2} from '../../dto/API.dto';
import InputSearch from '../../components/styles/InputSearch';
import {DialogClose} from '../../components/dialogs/DialogFrame/DialogClose';
import {SongSelectionRemoveButton} from '../../components/Button/SongSelectionRemoveButton';
import {SongSelectionAddButton} from '../../components/Button/SongSelectionAddButton';
import IconDeleteButton from '../../components/Button/IconDeletebutton';
import {GridDurationColumn} from '../../components/grid/GridColumns';

const MessageTrackUploadButton = ({
  type,
  isSingleSongSelection,
  selectedTracks,
  setSelecetedTracks,
  companyIsEmpty = false,
  isDisabled = false,
  isFullWidth = false,
}: {
  type: string;
  isSingleSongSelection: boolean; // true - select only single song, false - select multiple songs.
  selectedTracks: IMediaTrack[];
  setSelecetedTracks: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
  companyIsEmpty?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedSongs, setSelectedSongs] = useState<IMediaTrack[]>([]);

  const handleClose = () => {
    setSelectedSongs([]);
    setOpen(false);
  };
  const handleAdd = () => {
    setSelecetedTracks(selectedSongs);
    setSelectedSongs([]);
    setOpen(false);
  };

  useEffect(() => {
    setSelectedSongs(selectedTracks);
  }, [selectedTracks]);

  return (
    <Box
      className='mb-3'
      sx={{float: isFullWidth ? '' : 'right', right: '24px'}}>
      <Button
        className='mb-2'
        component='label'
        variant='outlined'
        startIcon={<CloudUpload />}
        onClick={() => setOpen(true)}
        disabled={isDisabled}
        fullWidth={isFullWidth}>
        {type}
      </Button>
      <Dialog
        fullWidth
        open={open}
        maxWidth='lg'
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle>
          <Typography>Select Songs</Typography>
          <DialogClose onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <SongSelectionDataGrid
            isSingleSongSelection={isSingleSongSelection}
            setOpen={setOpen}
            selectedSongs={selectedSongs}
            setSelectedSongs={setSelectedSongs}
            companyIsEmpty={companyIsEmpty}
          />
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} direction='row'>
            <Button
              onClick={handleAdd}
              variant='contained'
              autoFocus
              className='min-w-150'>
              Add
            </Button>
            <Button
              onClick={handleClose}
              variant='outlined'
              autoFocus
              color='info'>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageTrackUploadButton;

const SongSelectionDataGrid = ({
  isSingleSongSelection,
  setOpen,
  selectedSongs,
  setSelectedSongs,
  companyIsEmpty,
}: {
  isSingleSongSelection: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSongs: IMediaTrack[];
  setSelectedSongs: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
  companyIsEmpty: boolean;
}) => {
  const [query, setQuery] = useState<IDataGridProSearchSort>({
    ...defaultDataGridProSearchSort,
    track_category: 1, // is campign tracks,
  });
  const companySelector = useStoreSelector(companyIdSelector);
  const [companyId, setCompanyId] = useState(companySelector);
  const [trackDetails, settrackDetails] =
    useState<IApiPaginationResponse2<IMediaTrack>>();

  const taskParam = useParams();
  const taskId = Number(taskParam.task_id);
  const {data: taskDetails, isFetching: taskIsFetching} = useRqTaskInfo(
    taskId,
    Boolean(taskId) && !Boolean(companyId),
  );
  const {data, isLoading} = useRqMediaTracksPaginate(
    query,
    companyId,
    !companyIsEmpty,
  );
  const {data: trackData, isLoading: trackIsLoading} =
    useRqMediaTracksWithoutCompanyPaginate(query, companyIsEmpty);
  const [selectedSongsIds, setSelectedSongsIds] = useState<number[]>([]);

  useEffect(() => {
    if (data && !isLoading) {
      settrackDetails(data);
    } else if (trackData && !trackIsLoading) {
      settrackDetails(trackData);
    }
  }, [data, isLoading, trackData, trackIsLoading]);

  const handleSearch = useCallback((val: string) => {
    setQuery(prev => ({
      ...prev,
      search: val,
    }));
  }, []);
  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    setQuery(prev => ({...prev, sort: model}));
  };

  const handleSongRemove = useCallback(
    (musicTrack: IMediaTrack) => {
      setSelectedSongs(prev => {
        const filteredSelectedSongs = prev.filter(
          val => val.id !== musicTrack.id,
        );
        return filteredSelectedSongs;
      });
    },
    [setSelectedSongs],
  );

  const handleSongAdd = useCallback(
    (musicTrack: IMediaTrack) => {
      if (isSingleSongSelection) {
        setSelectedSongs([musicTrack]);
        setSelectedSongsIds([musicTrack.id]);
      } else {
        setSelectedSongs(prev => {
          setSelectedSongsIds(prevIds => {
            if (!prevIds.includes(musicTrack.id)) {
              return [...prevIds, musicTrack.id];
            }
            return prevIds;
          });
          if (!selectedSongsIds.includes(musicTrack.id)) {
            return [...prev, musicTrack];
          }
          return prev;
        });
      }
      setOpen(true);
    },
    [isSingleSongSelection, setOpen, setSelectedSongs, selectedSongsIds],
  );

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'id',
          type: 'actions',
          headerName: 'Play',
          width: 54,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            params.row.url ? (
              <MusicPreviewBtn id={params.row.id} url={params.row.url} />
            ) : null,
        },
        {
          field: 'track_name',
          headerName: 'Song title',
          flex: 1,
        },
        {
          field: 'track_artist',
          headerName: 'Artist',
          flex: 1,
        },
        GridDurationColumn,
        {
          type: 'actions',
          field: 'actions',
          headerName: '',
          hideable: false,
          width: 75,
          renderCell: (params: GridRenderCellParams) => {
            return selectedSongsIds?.includes(params.row.id) ? (
              <SongSelectionRemoveButton
                onClick={() => handleSongRemove(params.row)}
              />
            ) : (
              <SongSelectionAddButton
                onClick={() => handleSongAdd(params.row)}
              />
            );
          },
        },
      ].map(column => ({
        ...column,
        sortable: false,
        filterable: false,
      })),
    [handleSongAdd, handleSongRemove, selectedSongsIds],
  );

  useEffect(() => {
    setSelectedSongsIds(selectedSongs.map(val => val.id));
  }, [selectedSongs]);

  useEffect(() => {
    if (taskDetails && !taskIsFetching) {
      setCompanyId(taskDetails.company?.id ?? 0);
    }
  }, [taskDetails, taskIsFetching]);

  return (
    <Stack>
      <InputSearch
        className='max-w-347 block ml-auto'
        placeholder='Search Song'
        value={`${query.search}`}
        onChange={handleSearch}
      />

      <Box className='mt-1 h-454'>
        <DataGridPro
          rows={trackDetails?.data ?? []}
          columns={columns}
          loading={isLoading && trackIsLoading}
          rowCount={trackDetails?.total ?? 0}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={{page: query.page, pageSize: query.pageSize}}
          paginationMode='server'
          pagination={true}
          onPaginationModelChange={handlePagination}
          onSortModelChange={handleSortModelChange}
          density='compact'
          disableRowSelectionOnClick
          disableColumnMenu
          slots={{
            pagination: DataGridCustPagination,
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export const MessageTrackReviewCmpt = ({
  mediaTrack,
  setSelecetedTracks,
}: {
  mediaTrack: IMediaTrack;
  setSelecetedTracks?: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
}) => {
  const handleDelete = () => {
    if (setSelecetedTracks) {
      setSelecetedTracks(prev => {
        const removedData = prev.filter(val => {
          return val.id !== mediaTrack.id;
        });
        return removedData;
      });
    }
  };
  return (
    <Box className='flex items-center justify-start gap-3 my-5'>
      <MusicPreviewBtn id={mediaTrack.id} url={mediaTrack.url} />
      <Typography>{mediaTrack.track_name}</Typography>
      {setSelecetedTracks ? <IconDeleteButton onClick={handleDelete} /> : null}
    </Box>
  );
};

type IMessageTrackProp = {
  id: number;
  name: string;
  media_track: IMediaTrack;
};
const defaultApiState = {
  status: '',
  error: null,
  successMsg: '',
};
export const MessageTrackNamingDialog = ({
  type,
  id,
  url,
  open,
  setOpen,
  selectedTracks,
  setSelecetedTracks,
}: {
  url: string;
  id: number;
  type: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedTracks: IMediaTrack[];
  setSelecetedTracks: React.Dispatch<React.SetStateAction<IMediaTrack[]>>;
}) => {
  const classes = useStylesed();
  const title = type === 'stinger' ? 'Generic Stinger' : 'Generic Base';
  const [messageTrack, setMessageTrack] = useState<IMessageTrackProp[]>([]);
  const [apiState, setApiState] = useState(defaultApiState);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setSelecetedTracks([]);
    setApiState(defaultApiState);
    setOpen(false);
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>, id: number) => {
      setMessageTrack(prev => {
        const updatedName = prev.map(val => {
          if (val.id === id) {
            return {...val, name: e.target.value};
          }
          return val;
        });
        return updatedName;
      });
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    const message = messageTrack.map(({name, media_track}) => ({
      name,
      media_track_id: media_track?.id ?? null,
    }));
    const payload = {
      messages: message,
    };
    apiPost(url, payload)
      .then(res => {
        setApiState({
          status: 'success',
          error: null,
          successMsg: res.data.message,
        });
        if (type === 'stinger') {
          queryClient.invalidateQueries({
            queryKey: [queryKeyGenericStingerTracksRequestList(id)],
            refetchType: 'active',
          });
        } else if (type === 'base') {
          queryClient.invalidateQueries({
            queryKey: [queryKeyGenericBasetList(id)],
            refetchType: 'active',
          });
        }
      })
      .catch(error => {
        setApiState({status: 'error', error, successMsg: ''});
      });
  }, [id, messageTrack, queryClient, type, url]);

  useEffect(() => {
    const messageTrackData = selectedTracks.map((value, index) => {
      return {
        id: index,
        name: '',
        media_track: value,
      };
    });

    setMessageTrack(messageTrackData);
  }, [selectedTracks]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Typography>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          {messageTrack.length > 0
            ? messageTrack.map(value => (
                <React.Fragment key={value.id}>
                  <TextareaAutosize
                    placeholder='Message Name'
                    id={`msg-name-${value.id}`}
                    value={value.name}
                    maxRows={1}
                    className={classes.gn_textArea}
                    onChange={e => handleChange(e, value.id)}
                  />
                  <MessageTrackReviewCmpt mediaTrack={value.media_track} />
                </React.Fragment>
              ))
            : null}
          <Typography mt={1} ml={1} className='text-red-1'>
            <b>Note : </b>The below listed files will be directly uploaded to
            the global section without any review/processing.
          </Typography>
        </DialogContent>
        <DialogActions className='py-5'>
          <Button
            variant='contained'
            className='min-w-150'
            onClick={handleSubmit}
            disabled={messageTrack.some(val => val.name.trim().length === 0)}>
            Submit
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          onEdit={() => setApiState(defaultApiState)}
          onRetry={handleSubmit}
          onClose={handleClose}
          apiState={apiState}
        />
      )}
    </>
  );
};
