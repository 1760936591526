import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {useSelector} from 'react-redux';
import {IScripts} from './BaseMessagesReducer';
import {useRqListSeasons} from '../../../../react_query/seasons/ListSeasons';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
} from '../../../../store/Slices/companySlice';
import {
  addNewMessageToBaseMessages,
  invalidBaseMessage,
  invalidBaseMessageName,
  invalidBaseSeason,
  updateBaseMessages,
} from './BaseMessageFunctionalities';
import {IEventType} from '../../../../dto/EventType.dto';
import MessageAddButton from '../../../../components/styles/buttons/MessageAddButton';
import {MessageAlert} from '../../../messages/event-specific/EsmMessages';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import EventsDropdownGetEvents from '../../../../components/models/event/EventsDropdownGetEvents';
import {cleanString} from '../../../../Service/CommonService';
import CommonCusDropDown from '../../../messages/on-demand-msg/CommonCusDropDown';
import {CustomTextarea} from '../../../../components/styles/CustomTextarea';

const BaseMessageForm = ({
  message,
  setMessages,
  isNewBaseMessage,
  isEditForm = false,
  setOthersEventName,
  othersEventName,
  events,
  setEvents,
}: {
  message: IScripts;
  setMessages: Dispatch<SetStateAction<IScripts[]>>;
  isNewBaseMessage: boolean;
  isEditForm: boolean;
  othersEventName: string;
  setOthersEventName: React.Dispatch<React.SetStateAction<string>>;
  events: IEventType[];
  setEvents: React.Dispatch<React.SetStateAction<IEventType[]>>;
}) => {
  const isNRSZ = useSelector(isNRorSZSelector);
  const isGT = useSelector(isGTSelector);
  const companyId = useSelector(companyIdSelector);

  const {data} = useRqListSeasons(
    Number(companyId),
    Boolean(companyId) && isNRSZ,
  );

  const handleName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMessages(prev =>
        updateBaseMessages({
          messages: prev,
          uuid: message.uuid,
          value: {...message, name: event.target.value},
        }),
      );
    },
    [message, setMessages],
  );

  const handleYearAround = ({
    target: {checked: isChecked},
  }: ChangeEvent<HTMLInputElement>) => {
    setMessages(prev =>
      updateBaseMessages({
        messages: prev,
        uuid: message.uuid,
        value: {...message, isAllSeason: isChecked, seasons: []},
      }),
    );
  };

  const handleMessage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessages(prev =>
        updateBaseMessages({
          messages: prev,
          uuid: message.uuid,
          value: {...message, message: event.target.value},
        }),
      );
    },
    [message, setMessages],
  );

  const handleAddMessageDetails = useCallback(() => {
    setMessages(prev =>
      addNewMessageToBaseMessages({
        messages: prev,
      }),
    );
    setEvents([]);
  }, [setEvents, setMessages]);

  const inValidForm = useMemo(() => {
    if (
      invalidBaseMessageName(`${message?.name}`) ||
      invalidBaseMessage(message)
    ) {
      return true;
    }
    if (isNRSZ && invalidBaseSeason(message)) {
      return true;
    } else if (
      isGT &&
      (message?.events.length === 0 ||
        (message?.events.some(obj => obj.id === 0) && !othersEventName))
    ) {
      return true;
    }
    return false;
  }, [isGT, isNRSZ, message, othersEventName]);

  useEffect(() => {
    setMessages(prev =>
      prev.map(o => {
        if (message.uuid === o.uuid) {
          return {
            ...o,
            events,
          };
        }
        return o;
      }),
    );
  }, [events, message?.uuid, setMessages]);

  const handleOtherEvents = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = e.target;
      setMessages(prev =>
        prev.map(o => {
          if (message.uuid === o.uuid) {
            const updatedEvents = o.events.map(x => {
              if (x.id === 0) {
                return {
                  ...x,
                  event_name: value,
                  event_short_name: cleanString(value).toUpperCase(),
                };
              }
              return x;
            });

            return {
              ...o,
              events: updatedEvents,
            };
          }
          return o;
        }),
      );
      setOthersEventName(value);
    },
    [setOthersEventName, message?.uuid, setMessages],
  );
  const handleCancelSeason = (SeasonId: number) => {
    setMessages(prevSeasons =>
      prevSeasons.map(prevSeason => {
        if (prevSeason.seasons.some(season => season.id === SeasonId)) {
          return {
            ...prevSeason,
            seasons: prevSeason.seasons.filter(
              season => season.id !== SeasonId,
            ),
          };
        } else {
          return prevSeason;
        }
      }),
    );
  };

  const deselectAllSeasons = () => {
    setMessages(prevMessages =>
      prevMessages.map(message => ({
        ...message,
        seasons: [],
      })),
    );
  };

  return (
    <>
      <MessageAlert name={`${message?.name}`} message={message?.message} />
      <Box>
        <Grid container alignItems='center'>
          <Grid item md={6}>
            <TextField
              id='message-name-text'
              placeholder='Message Name*'
              variant='outlined'
              value={message?.name}
              onChange={handleName}
              className='m-h-40 h-40 w-full max-w-400 p-0 my-3'
              InputProps={{
                style: {
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Box className='w-400 mb-1 mx-1'>
              {isGT ? (
                <EventsDropdownGetEvents
                  selected={message?.events}
                  setSelected={setEvents}
                  othersEventName={othersEventName}
                  handleOtherEvents={handleOtherEvents}
                />
              ) : (
                <>
                  <Stack direction='row' className='my-3'>
                    <FormControlLabel
                      className='w-200 ml-0 flex-nowrap'
                      control={
                        <Checkbox
                          disableRipple
                          checked={message?.isAllSeason}
                          onChange={handleYearAround}
                        />
                      }
                      label='Year Around'
                    />
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id='season'
                      sx={{
                        width: 300,
                        '.MuiInputBase-root .MuiButtonBase-root': {
                          display: 'none',
                        },
                      }}
                      className='cusAutoSelect w-full max-300 overflow-hidden custDpIcon'
                      onChange={(_, seasons) => {
                        setMessages(prev =>
                          updateBaseMessages({
                            messages: prev,
                            uuid: message.uuid,
                            value: {...message, seasons},
                          }),
                        );
                      }}
                      disabled={message.isAllSeason}
                      value={message.seasons}
                      options={data ?? []}
                      getOptionLabel={option => option.season_name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={params => (
                        <TextField {...params} placeholder='Select seasons' />
                      )}
                      renderOption={(props, option, {selected}) => (
                        <li
                          {...props}
                          className='autoselect-option options-select flex items-center pl-6 cursor-pointer'>
                          <Typography
                            component='h6'
                            className='flex items-center'>
                            <Checkbox
                              checked={selected}
                              sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
                            />
                            {option.season_name}
                          </Typography>
                        </li>
                      )}
                    />
                  </Stack>
                  {message.seasons.length > 0 && (
                    <CommonCusDropDown
                      showData={message.seasons}
                      cancelData={handleCancelSeason}
                      deselectAll={deselectAllSeasons}
                    />
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>

        <Stack
          spacing={1}
          direction='row'
          alignItems='end'
          className='textarea_newmsg mb-5'>
          <CustomTextarea
            placeholder='Enter Message here'
            onChange={handleMessage}
            value={message?.message}
          />
          <Stack spacing={2}>
            {message?.media_track ? (
              <MusicPreviewBtn
                id={message.uuid}
                url={message.media_track.url}
              />
            ) : null}
            {!isEditForm ? (
              <MessageAddButton
                isValid={!inValidForm}
                onClick={handleAddMessageDetails}
              />
            ) : null}
          </Stack>
        </Stack>
      </Box>
      {isNewBaseMessage ? (
        <Typography className='text-13 text-white opacity-70'>
          Note: You will need a minimum of {isGT ? 'five' : 'six'} base messages
          before you will be able to submit your request.
        </Typography>
      ) : null}
    </>
  );
};

export default BaseMessageForm;
