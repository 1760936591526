import {Typography} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import SecondsNumberToTimeFormat from '../utlis/date-time/SecondsNumberToTimeFormat';

export const GridDurationColumn = {
  field: 'duration',
  headerName: 'Duration',
  width: 82,
  renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
    params.row.duration ? (
      <Typography variant='caption'>
        {SecondsNumberToTimeFormat(params.row.duration)}
      </Typography>
    ) : null,
};
