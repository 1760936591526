import {v4 as uuidv4} from 'uuid';
import {useSelector} from 'react-redux';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useMemo, useState} from 'react';
import {apiPost} from '../../../../axiosConfig';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import {
  StepperLayout,
  useStepperState,
} from '../../../../components/layouts/stepper/StepperLayout';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
  productLevelSelector,
} from '../../../../store/Slices/companySlice';
import BaseMessageInput from './BaseMessageInput';
import BaseMessageReview from './BaseMessageReview';
import {IScripts} from './BaseMessagesReducer';
import BaseVoiceArtistSelection from './BaseVoiceArtistSelection';
import {
  useRqBaseMessageVoiceArtist,
  useRqBaseMessagesCount,
} from '../../../../react_query/base-messages/BaseMessageRequestQueries';
import {
  queryKeyMessageRequestList,
  useRqViewBaseListMessagesByRequestID,
} from '../../../../react_query/messages/MessagesByCmpId';
import {
  addNewTackToBaseMessages,
  inValidBaseMessageExists,
} from './BaseMessageFunctionalities';
import {IMediaTrack} from '../../../../dto/MediaTrack.dto';
import BaseSeasonAndTracksAdd from './BaseSeasonAndTracksAdd';
import {IEventType} from '../../../../dto/EventType.dto';

const BaseMessageRequest = ({
  handleCancel,
  id,
}: {
  handleCancel: () => void;
  id?: number;
}) => {
  const isNRSZ = useSelector(isNRorSZSelector);
  const isGT = useSelector(isGTSelector);
  const productLevel = useSelector(productLevelSelector);
  const isLevel1 = productLevel.includes('Level 1');
  const companyId = useSelector(companyIdSelector);
  const [messages, setMessages] = useState<IScripts[]>([
    {
      id: Date.now(),
      uuid: uuidv4(),
      name: '',
      message: '',
      events: [],
      seasons: [],
      isAllSeason: true,
      requestable_type: 'base-message',
    },
  ]);
  const [voice, setVoice] = useState<string[]>([]);
  const [voiceName, setVoiceName] = useState<string[]>([]);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [selectedTracks, setSelectedTracks] = useState<IMediaTrack[]>([]);
  const {activeStepIndex, onNextStep, onPreviousStep, onStepClick} =
    useStepperState(0);
  const [othersEventName, setOthersEventName] = useState('');
  const [events, setEvents] = useState<IEventType[]>([]);

  const {data: baseCount, isFetching: baseCountFetching} =
    useRqBaseMessagesCount(companyId);

  const {data: baseVoice} = useRqBaseMessageVoiceArtist(companyId);

  const baseMessageRequestId = Number(id);
  const {data, isFetching} = useRqViewBaseListMessagesByRequestID(
    companyId,
    baseMessageRequestId,
    Boolean(baseMessageRequestId),
  );

  const queryClient = useQueryClient();

  const isNewBaseMessage = useMemo(() => {
    if (baseCount && !baseCountFetching && baseCount.count === 0) {
      return true;
    }
    return false;
  }, [baseCount, baseCountFetching]);

  const isEventSportsAvail = useMemo(() => {
    if (isGT) {
      return !messages.some(
        msg => msg.events.some(event => event.id === 0) && !othersEventName,
      );
    }
    return true;
  }, [messages, othersEventName, isGT]);

  const isSeasonAvail = useMemo(() => {
    if (!isGT) {
      let checkSess = messages.some(obj => {
        return (
          obj.isAllSeason === false &&
          obj?.seasonId &&
          obj?.seasonId?.length === 0
        );
      });
      if (checkSess) {
        return false;
      }
    }
    return true;
  }, [messages, isGT]);

  const isInvalidMessages = useMemo(() => {
    return inValidBaseMessageExists({isGT, isNRSZ, isNewBaseMessage, messages});
  }, [isGT, isNRSZ, isNewBaseMessage, messages]);

  const steps = useMemo(() => {
    if (baseCountFetching) {
      return [];
    }
    const voiceVerify =
      isNRSZ && isNewBaseMessage
        ? isLevel1
          ? !(voice.length === 1)
          : !(voice.length === 3)
        : false;
    const defaultSteps = [
      {
        label: id ? 'Edit Message' : 'Create Message',
        disabled: voiceVerify,
      },
      {
        label: 'Review',
        disabled: isInvalidMessages || !isEventSportsAvail || !isSeasonAvail,
      },
    ];

    if (isNRSZ && (isNewBaseMessage || baseVoice?.length === 0)) {
      return [
        {
          label: 'Select Voice Artist',
          disabled: false,
        },
        ...defaultSteps,
      ];
    }
    return defaultSteps;
  }, [
    baseCountFetching,
    isNRSZ,
    isNewBaseMessage,
    isLevel1,
    voice.length,
    id,
    isInvalidMessages,
    isEventSportsAvail,
    isSeasonAvail,
    baseVoice,
  ]);
  const handleSubmit = () => {
    let apiPath = '';
    let payload = {};
    if (baseMessageRequestId) {
      let payloadDetails = {};
      messages
        .filter(({message, name}) => message !== '' && name !== '')
        .forEach((val: IScripts & {media_track_id?: number}) => {
          const seasonIds = val.seasons.map(season => season.id);
          val.seasonId = seasonIds;
          const eventIds = val.events.map(event => event.id);
          val.eventIds = eventIds;
          payloadDetails = val; // Ensure you return the modified val
        });
      payload = {
        ...payloadDetails,
      };
      apiPath = `/v1/company/${companyId}/message/base-message/${baseMessageRequestId}?_method=PUT`;
    } else {
      const payloadDetails = messages
        .filter(({message, name}) => message !== '' && name !== '')
        .map((val: IScripts & {media_track_id?: number}) => {
          const seasonIds = val.seasons.map(season => season.id);
          val.seasonId = seasonIds;
          const eventIds = val.events.map(event => event.id);
          val.eventIds = eventIds;
          if (val.media_track) {
            val.media_track_id = val.media_track.id;
          }
          return val; // Ensure you return the modified val
        });
      payload = {
        messages: payloadDetails,
        voices: voice,
      };
      apiPath = `/v1/company/${companyId}/message/base-message`;
    }
    apiPost(apiPath, payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'base-message')],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };
  const handleEdit = () => {
    setApiState({
      status: '',
      error: null,
    });
  };

  useEffect(() => {
    selectedTracks.forEach(track => {
      setMessages(
        prev =>
          addNewTackToBaseMessages({
            messages: prev,
            track,
          }) as IScripts[],
      );
    });
  }, [selectedTracks, setMessages]);

  useEffect(() => {
    if (data && !isFetching) {
      setMessages([
        {
          id: data.id,
          uuid: uuidv4(),
          message: data.message?.message ?? '',
          name: data.name ?? '',
          events: data.events ?? [],
          requestable_type: 'base-message',
          seasons: data.seasons ?? [],
          isAllSeason: Boolean(data.is_all_seasons),
          media_track: data.message?.media_track ?? undefined,
        },
      ]);
      setEvents(data.events ?? []);
      if (data.voice_artists) {
        setVoiceName(data.voice_artists);
      }
    }
  }, [data, isFetching]);

  return (
    <StepperLayout
      steps={steps}
      activeStepIndex={activeStepIndex}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onStepClick={onStepClick}
      isSubmitted={apiState.status !== ''}
      isLastHide={false}
      title={`${id ? 'Edit' : 'Create New '} Message `}>
      {(() => {
        const label = steps?.[activeStepIndex]?.label ?? '';
        switch (label) {
          case 'Select Voice Artist':
            return (
              <BaseVoiceArtistSelection
                voice={voice}
                setVoice={setVoice}
                setVoiceName={setVoiceName}
              />
            );
          case 'Edit Message':
          case 'Create Message':
            return (
              <>
                <BaseSeasonAndTracksAdd
                  selectedTracks={selectedTracks}
                  setSelectedTracks={setSelectedTracks}
                  title={`${id ? 'Edit' : 'Create'} Message`}
                />
                <BaseMessageInput
                  messages={messages}
                  setMessages={setMessages}
                  isNewBaseMessage={isNewBaseMessage}
                  setSelectedTracks={setSelectedTracks}
                  setOthersEventName={setOthersEventName}
                  othersEventName={othersEventName}
                  setEvents={setEvents}
                  events={events}
                />
              </>
            );

          case 'Review':
            return (
              <BaseMessageReview
                messages={messages}
                voiceName={voiceName}
                setMessages={setMessages}
              />
            );

          default:
            return null;
        }
      })()}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleCancel}
        />
      )}
    </StepperLayout>
  );
};

export default BaseMessageRequest;
