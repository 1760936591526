import React, {useCallback} from 'react';
import {Box, Stack, TextField, Typography} from '@mui/material';
import {iTimedMessageMessageItem} from './TimedMsgRequestContentMessageCard';

type Props = {
  message: iTimedMessageMessageItem;
  onChange: (message: iTimedMessageMessageItem) => void;
};

const TimedMsgNewSongRequest = ({message, onChange}: Props) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let errorMsg = '',
        val = event.target.value.trim();
      const name = event.target.name;
      if (val.length > 50) {
        errorMsg = 'This must be at most 50 characters';
      }
      onChange({
        ...message,
        [name]: event.target.value,
        [name + 'ErrorMsg']: errorMsg,
      });
    },
    [message, onChange],
  );

  return (
    <Stack spacing={2} className='flex items-center justify-center'>
      <Typography>
        Still haven't found the song you're looking for? Enter the Artist and
        Song Title, we will review it
      </Typography>
      <Box className='flex mt-1 mb-2'>
        <TextField
          size='small'
          variant='outlined'
          name='artist'
          value={message.artist}
          label='Artist'
          onChange={handleChange}
          className='mr-3'
          // onBlur={() => handleBlur('artist')}
          error={Boolean(message.artistErrorMsg)}
          helperText={message.artistErrorMsg}
        />
        <TextField
          size='small'
          variant='outlined'
          name='title'
          value={message.title}
          label='Song Title'
          onChange={handleChange}
          error={Boolean(message.titleErrorMsg)}
          helperText={message.titleErrorMsg}
        />
      </Box>
    </Stack>
  );
};

export default TimedMsgNewSongRequest;
