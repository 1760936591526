import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {IStation} from '../../../dto/Station.dto';
import TimedMessageRequest, {
  iTimedMsgDateRange,
} from './request/TimedMessageRequest';
import {iTimedMsgTimesItem} from './request/times/TimedMsgRequestTimesCard';
import {iTimedMessageMessageItem} from './request/contents/TimedMsgRequestContentMessageCard';
import {
  ITimedMessageDayType,
  ITimedMessageMsgType,
} from '../../../dto/timed-message/TimedMessageRequest.dto';
import {useRqViewTimedMessagesByRequestID} from '../../../react_query/messages/timed-messages/ViewTimedMsgDetails';
import {companyIdSelector} from '../../../store/Slices/companySlice';

const defaultMessages: iTimedMessageMessageItem[] = [
  {
    id: Date.now(),
    type: 'message',
    message: '',
    artist: '',
    title: '',
    label: 'Message',
  },
];

const defaultState = {
  defaultName: '',
  defaultStep: 0,
  defaultStations: [] as IStation[],
  defaultMessages,
  defaultDayType: 'no-end' as ITimedMessageDayType,
  defaultContentType: 'message' as ITimedMessageMsgType,
  defaultDateRanges: [
    {
      id: Date.now(),
      start_date: null,
      end_date: null,
    } as iTimedMsgDateRange,
  ],
  defaultTimeItems: [
    {
      id: Date.now(),
      selectedDays: [],
      timeRanges: [],
    },
  ] as iTimedMsgTimesItem[],
};

const TimedMessageCreate = ({
  onClose,
  restoreId,
  duplicateId,
  setStatus,
}: {
  onClose: () => void;
  restoreId?: number;
  duplicateId?: number;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const companyId = useSelector(companyIdSelector);
  const [state, setState] = useState({...defaultState});

  const {data, isFetching, isError} = useRqViewTimedMessagesByRequestID(
    Number(companyId),
    Number(duplicateId),
    Boolean(duplicateId),
  );

  useEffect(() => {
    if (data && !isFetching && !isError) {
      const defaultMessages: iTimedMessageMessageItem[] =
        data.timed_sequences.map(o => {
          return {
            id: o.id,
            type: o.sequenceable_type === 'message' ? 'message' : 'song',
            message:
              o.sequenceable_type === 'message' ? o.sequenceable.message : '',
            artist: o.sequenceable.media_track?.track_artist ?? '',
            title: o.sequenceable.media_track?.track_name ?? '',
            label: o.sequenceable_type === 'message' ? 'Message' : 'Song',
            track: o.sequenceable.media_track,
          };
        });
      setState(prev => ({
        ...prev,
        defaultName: `${data.name} duplicate`,
        defaultStations: data.stations,
        defaultMessages: defaultMessages,
        defaultDayType: data.day_type,
        defaultContentType: data.message_type,
        defaultDateRanges: data.date_ranges.map(o => ({
          id: o.id,
          start_date: dayjs(o.start_date),
          end_date: dayjs(o.end_date),
        })),
        defaultTimeItems: data.day_times.map(o => {
          return {
            id: o.id,
            selectedDays: o.week_days.map(
              d => d.charAt(0).toUpperCase() + d.slice(1).toLowerCase(),
            ),
            timeRanges: o.start_times.map((t, i) => ({
              id: i,
              start_time: dayjs(t, 'HH:mm:ss'),
            })) as [],
          };
        }),
      }));
    }
  }, [data, isError, isFetching]);

  if (duplicateId) {
    return !isFetching && state.defaultName ? (
      <TimedMessageRequest
        onClose={onClose}
        {...state}
        isCopyForm
        setStatus={setStatus}
      />
    ) : null;
  }
  return (
    <TimedMessageRequest
      onClose={onClose}
      {...defaultState}
      restoreId={restoreId}
      setStatus={setStatus}
    />
  );
};

export default TimedMessageCreate;
