import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Autocomplete, Checkbox, TextField, Typography} from '@mui/material';
import {useRqCompanyWithSchools} from '../../../react_query/companies/Company';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';

import CommonCusDropDown from '../../../pages/messages/on-demand-msg/CommonCusDropDown';

const SchoolsDropdownGetSchools = ({
  selected,
  setSelected,
}: {
  selected: ICompanySchool[];
  setSelected: React.Dispatch<React.SetStateAction<ICompanySchool[]>>;
}) => {
  const [schools, setSchools] = useState<ICompanySchool[]>([]);
  const companyId = useSelector(companyIdSelector);

  const {data, isFetching, isError} = useRqCompanyWithSchools(companyId);

  useEffect(() => {
    if (data && !isFetching && !isError) {
      setSchools([...data.schools]);
      if (data.schools.length === 1) {
        setSelected([data.schools[0] as ICompanySchool]);
      }
    }
  }, [data, isFetching, isError, setSelected]);

  const handleCancelSchool = (schoolsId: number) => {
    setSelected(prevSchools =>
      prevSchools.filter(School => School.id !== schoolsId),
    );
  };

  const deselectAllSchools = () => {
    setSelected([]);
  };

  return (
    <>
      <Autocomplete
        multiple
        fullWidth
        disableCloseOnSelect
        id='schools'
        sx={{
          width: 300,
          '.MuiInputBase-root .MuiButtonBase-root': {display: 'none'},
        }}
        onChange={(
          _: React.SyntheticEvent<Element, Event>,
          values: readonly ICompanySchool[],
        ) => {
          const result = values.find(obj => obj.id === 0);
          if (result) {
            setSelected([...schools.filter(obj => obj.id !== 0)]);
          } else {
            setSelected([...values]);
          }
        }}
        className='h-55 max-w-348 w-full m-0 mr-10 relative custDpIcon'
        value={selected}
        defaultValue={selected}
        options={schools}
        getOptionLabel={(option: ICompanySchool) => option.name}
        renderInput={params => (
          <TextField {...params} placeholder='Select Schools' />
        )}
        renderOption={(props, option, {selected}) => (
          <li
            {...props}
            className='autoselect-option options-select flex items-center pl-6 cursor-pointer'>
            <Typography
              component='h6'
              className='flex items-center
           '>
              <Checkbox
                checked={selected}
                sx={{'& .MuiSvgIcon-root': {fontSize: 16}}}
              />
              {option.name}
            </Typography>
          </li>
        )}
      />
      {selected.length > 0 && (
        <CommonCusDropDown
          showData={selected}
          cancelData={handleCancelSchool}
          deselectAll={deselectAllSchools}
        />
      )}
    </>
  );
};

export default SchoolsDropdownGetSchools;
